import {Component, ElementRef, HostListener, Input, OnInit} from '@angular/core';
import {GenericInputComponent} from '../generic-input.component';
import {ControlContainer} from '@angular/forms';
import {SelectItem} from '@ui-forms/models/select-item';

@Component({
  selector: 'app-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss', '../generic-input.component.scss'],
})
export class SelectComponent extends GenericInputComponent implements OnInit {
  @Input() selectOptions: { key: unknown; label: string; notSelectable?: boolean }[];
  @Input() placeHolder = 'Bitte Auswählen';
  @Input() hasPlaceholderOption = false;
  @Input() isDisabled: boolean;
  @Input() isLoading: boolean;

  open = false;

  constructor(
    private control: ControlContainer,
    private element: ElementRef,
  ) {
    super(control);
  }

  get selectedOptionLabel(): string {
    return this.selectOptions?.find(option => option.key === this.currentValue)?.label ?? '-';
  }

  get dropdownIcon(): string {
    return this.open ? 'dropdown-up' : 'dropdown-down';
  }

  @HostListener('document:click', ['$event']) documentClick(event: MouseEvent): void {
    if (event.target !== this.element.nativeElement && !this.element.nativeElement.contains(event.target) && this.open) {
      this.open = false;
    }
  }

  ngOnInit(): void {
    this.init();
    if (this.hasPlaceholderOption) {
      this.selectOptions = [...[{key: '', label: this.placeHolder}], ...this.selectOptions];
    }
  }

  toggleOptions(): void {
    if (this.isDisabled || !this.selectOptions?.length) {
      return;
    }
    this.open = !this.open;
  }

  setOption(item: SelectItem): void {
    if (item.notSelectable || this.isDisabled) {return;}
    this.setCurrentValue(item.key);
    this.open = false;
  }
}
