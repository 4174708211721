<input
  [formControl]="formControl"
  hidden
  type="text"
>
<app-time-picker
  [label]="label"
  [date]="currentValue"
  ($timeSelected)="onDateSelected($event)"
  [isRequired] = "hasRequiredValidator"
  >
</app-time-picker
>