import {Route} from '@angular/router';
import {AuthGuard} from './guards/auth.guard';
import {DefaultGuard} from './guards/default.guard';
import {LoginPageGuard} from './guards/login-page-guard.guard';
import {LoginComponent} from './components/login/login.component';
import {ContactComponent} from './components/contact/contact.component';
import {PrivacyComponent} from './components/privacy/privacy.component';
import {ImprintComponent} from './components/imprint/imprint.component';
import {Role} from '@ui/enums/user-role.enum';
import {ForgotPasswordComponent} from './components/forgot-password/forgot-password.component';
import {ChangePasswordComponent} from './components/change-password/change-password.component';

export type LabelledRoute = Route & {
  label?: string;
  icon?: string;
  children?: LabelledRoute[];
  isNavigationItem?: true;
  roles?: Role[];
};

export const routes: LabelledRoute[] = [
  {
    path: '',
    canActivate: [AuthGuard],
    isNavigationItem: true,
    children: [
      {
        path: '',
        redirectTo: 'dashboard',
        pathMatch: 'full',
      },
      {
        path: 'dashboard',
        label: 'Dashboard',
        icon: 'start',
        loadChildren: () => import('../dashboard/dashboard.module').then((m) => m.DashboardModule),
      },
      {
        path: 'providers',
        label: 'Mandanten',
        icon: 'clients',
        loadChildren: () => import('../provider/provider.module').then((m) => m.ProviderModule),
      },
      {
        path: 'hubs',
        label: 'Hubs',
        icon: 'standort',
        loadChildren: () => import('../hub/hub.module').then((m) => m.HubModule),
      },
      {
        path: 'vehicletypes',
        label: 'Fahrzeugtypen',
        icon: 'vehicles',
        loadChildren: () => import('../vehicle-type/vehicle-type.module').then((m) => m.VehicleTypeModule),
      },
      {
        path: 'locks',
        label: 'Linka Schlösser',
        icon: 'lock_closed',
        loadChildren: () => import('../locks/locks.module').then((m) => m.LocksModule),
      },
      {
        path: 'vehicles',
        label: 'Fahrzeuge',
        icon: 'reifen',
        loadChildren: () => import('../vehicle/vehicle.module').then((m) => m.VehicleModule),
      },
      {
        path: 'bookings',
        label: 'Buchungen',
        icon: 'kalender',
        // roles: [...permission.BookingRead, ...permission.BookingPeriodRead],
        loadChildren: () => import('../booking/booking.module').then((m) => m.BookingModule),
      },
      {
        path: 'user-groups',
        label: 'Nutzergruppen',
        icon: 'user_group',
        loadChildren: () => import('../user-group/user-group.module').then((m) => m.UserGroupModule),
      },
      {
        path: 'tariffs',
        label: 'Tarife',
        icon: 'info_reservierung',
        loadChildren: () => import('../tariff/tariff.module').then((m) => m.TariffModule),
      },
      {
        path: 'users',
        label: 'Nutzer',
        icon: 'user_1',
        loadChildren: () => import('../user/user.module').then((m) => m.UserModule),
      },
      {
        path: 'service-partners',
        label: 'Service Partner',
        icon: 'service_partner',
        loadChildren: () => import('../service-partner/service-partner.module').then((m) => m.ServicePartnerModule),
      },
      {
        path: 'editors',
        label: 'Mandanten-Admins/Redakteure',
        icon: 'pencil',
        loadChildren: () => import('../editor/editor.module').then((m) => m.EditorModule),
      },
    ],
  },
  {
    path: 'login',
    canActivate: [LoginPageGuard],
    component: LoginComponent
  },
  {
    path: 'passwort-vergessen',
    canActivate: [LoginPageGuard],
    component: ForgotPasswordComponent
  },
  {
    path: 'passwort-aendern',
    canActivate: [LoginPageGuard],
    component: ChangePasswordComponent
  },
  {
    path: 'kontakt',
    component: ContactComponent
  },
  {
    path: 'datenschutz',
    component: PrivacyComponent
  },
  {
    path: 'impressum',
    component: ImprintComponent
  },
  {
    path: '**',
    canActivate: [DefaultGuard],
    children: [],
  }
];
