import {Component, Input, OnInit} from '@angular/core';
import {GenericInputComponent} from '../generic-input.component';
import {ControlContainer} from '@angular/forms';

@Component({
  selector: 'app-input-file',
  templateUrl: './input-file.component.html',
  styleUrls: ['./input-file.component.scss', '../generic-input.component.scss'],
})
export class InputFileComponent extends GenericInputComponent implements OnInit {
  @Input() imageSrc: string;

  fileName: string;
  imageAsBase64: string;

  constructor(
    private control: ControlContainer,
  ) {
    super(control);
  }

  get image(): string {
    return this.imageAsBase64 || this.imageSrc || '';
  }

  ngOnInit(): void {
    this.init();
    this.formControl.valueChanges.subscribe(value => {
      /* if value is reset, set image and filename to null*/
      if (!value) {
        this.imageAsBase64 = null;
        this.fileName = null;
      }
    });
  }

  onFileChange(event): void {
    const reader = new FileReader();

    if (event.target.files && event.target.files.length) {
      const [file] = event.target.files;
      this.setCurrentValue(file);
      this.fileName = file.name;

      reader.readAsDataURL(file);
      reader.onload = () => {
        this.imageAsBase64 = reader.result as string;
      };
    }
  }
}
