import {animate, state, style, transition, trigger} from '@angular/animations';
import {Component} from '@angular/core';
import {Toast, ToastType} from '../../models/toast';
import {ToastService} from '../../services/toast.service';

@Component({
  selector: 'app-toast',
  templateUrl: './toast.component.html',
  styleUrls: [ './toast.component.scss' ],
  animations: [
    trigger('fadeInAnimation', [
      state('in', style({opacity: 1})),
      transition(':enter', [ style({opacity: 0}), animate(125) ]),
      transition(':leave', animate(125, style({opacity: 0})))
    ])
  ]
})
export class ToastComponent {
  constructor(private toastService: ToastService) {
  }

  get toasts(): Toast[] {
    return this.toastService.toasts;
  }

  getTypeClassNames(toast: Toast) {
    return {
      warning: toast.type === ToastType.Warning,
      error: toast.type === ToastType.Error,
      info: toast.type === ToastType.Info,
      success: toast.type === ToastType.Success,
    };
  }

  onCloseClicked(toast: Toast): void {
    this.toastService.removeToast(toast);
  }
}
