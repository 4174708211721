<div *ngIf="controlExists" class="input">
  <span class="input__label">{{label}}<span *ngIf = "hasRequiredValidator">*</span></span>
  <div class="input__select" [class.open]="open" [class.select-disable]="isDisabled">
    <div class="input__select__main" (click)="toggleOptions()">
      <div>
        <ng-container *ngIf="selectOptions?.length">
          <span *ngIf="hasValue">{{ selectedOptionLabel }}</span>
          <span *ngIf="!hasValue">{{ placeHolder }}</span>
        </ng-container>
        <ng-container *ngIf="!selectOptions?.length">
          <span>keine Optionen vorhanden</span>
        </ng-container>
      </div>
      <div>
        <app-loading-spinner class="spinner" *ngIf="isLoading"></app-loading-spinner>
        <span class="dropdown__caret">
          <span class="{{dropdownIcon}}">
        </span>
      </span>
      </div>
    </div>
    <div class="input__select__options" [class.open]="open">
      <span
        *ngFor="let item of selectOptions"
        (click)="setOption(item)"
        class="input__select__item"
        [class.selected]="currentValue === item.key"
        [class.notSelectable]="item.notSelectable"
      >
        {{ item.label }}
      </span>
    </div>
  </div>
</div>
