<form [formGroup]="form" [ngClass]="{'disabled': disabled}">
  <input
    #day
    (blur)="onBlur('day')"
    (focusin)="onFocus('day')"
    (keyup)="onKeypress('day')"
    class="day"
    formControlName="day"
    max="31"
    min="1"
    placeholder="TT"
    type="text"
  />

  <div class="separator">.</div>

  <input
    #month
    (blur)="onBlur('month')"
    (focusin)="onFocus('month')"
    (keyup)="onKeypress('month')"
    class="month"
    formControlName="month"
    max="12"
    min="1"
    placeholder="MM"
    type="text"
  />

  <div class="separator">.</div>

  <input
    #year
    (blur)="onBlur('year')"
    (focusin)="onFocus('year')"
    (keyup)="onKeypress('year')"
    class="year"
    formControlName="year"
    min="1900"
    placeholder="JJJJ"
    type="text"
  />
</form>
