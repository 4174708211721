import { Component, OnInit } from '@angular/core';
import {FormGroup, Validators, FormBuilder} from '@angular/forms';
import {ApiService} from '../../services/api.service';
import {Router} from '@angular/router';
import {LocalStorageService} from '../../services/local-storage.service';
import {ToastService} from '@ui/services/toast.service';
import {ToastType, Toast} from '@ui/models/toast';
import {SettingsKeyEnum} from '../../models/settings-key.enum';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {
  form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private toastService: ToastService
    ) {}

  ngOnInit(): void {
    this.initFormGroup();
  }


  onSubmit() {
    const {email} = this.form.controls;
    if(email.valid) {
     this.apiService.post('/api/users/password/request', {email: email.value}).subscribe(() => {
      this.localStorageService.set(SettingsKeyEnum.PasswordResetEmail, email.value);
      this.router.navigate(['passwort-aendern']);
      this.toastService.addToast(new Toast({
        text: `Sicherheitscode wurde an ${email.value} gesendet`,
        type: ToastType.Success,
      }));
      });
    }
  }

  goToLoginPage() {
    this.router.navigate(['login']);
  }

  private initFormGroup(): void {
    this.form = this.formBuilder.group({
      email: ['', [Validators.required, Validators.email]]
    });
  }
}
