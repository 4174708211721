import {Pipe, PipeTransform} from '@angular/core';
import {Role} from '@ui/enums/user-role.enum';
import {AuthService} from 'src/modules/app/services/auth.service';

@Pipe({
    name: 'enableForRole'
})
export class EnableForRole implements PipeTransform {
    constructor(
        private authService: AuthService
    ) {

    }
    transform(userRoles: Role[]): boolean {
        return this.authService.userHasRole(userRoles);
    }
}
