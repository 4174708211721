
import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {RouteReuseStrategy, RouterModule} from '@angular/router';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './components/app/app.component';
import {UiModule} from '@ui/ui.module';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NavigationComponent} from './components/navigation/navigation.component';
import {HTTP_INTERCEPTORS, HttpClientModule} from '@angular/common/http';
import {CustomHttpInterceptor} from './interceptors/custom-http.interceptor';
import {AuthGuard} from './guards/auth.guard';
import {DefaultGuard} from './guards/default.guard';
import {LoginPageGuard} from './guards/login-page-guard.guard';
import {LoginComponent} from './components/login/login.component';
import {UiFormsModule} from '@ui-forms/ui-forms.module';
import {ReactiveFormsModule} from '@angular/forms';
import {AuthService} from './services/auth.service';
import {ApiService} from './services/api.service';
import {LocalStorageService} from './services/local-storage.service';
import {NavigationService} from './services/navigation.service';
import {UiModalsModule} from '../ui-modals/ui-modals.module';
import {CustomRouteReuseStrategy} from '../../custom-route-reuse-strategy';
import {DatePipe} from '@angular/common';
import { FooterComponent } from './components/footer/footer.component';
import {ContactComponent} from './components/contact/contact.component';
import {PrivacyComponent} from './components/privacy/privacy.component';
import {ImprintComponent} from './components/imprint/imprint.component';
import { ForgotPasswordComponent } from './components/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from './components/change-password/change-password.component';

@NgModule({
  declarations: [
    AppComponent,
    NavigationComponent,
    LoginComponent,
    FooterComponent,
    ContactComponent,
    PrivacyComponent,
    ImprintComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent
  ],
  imports: [
    BrowserModule,
    ReactiveFormsModule,
    HttpClientModule,
    AppRoutingModule,
    RouterModule,
    UiModule,
    UiFormsModule,
    UiModalsModule,
    BrowserAnimationsModule,
  ],
  providers: [
    AuthService,
    ApiService,
    LocalStorageService,
    DatePipe,
    NavigationService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CustomHttpInterceptor,
      multi: true,
    },
    AuthGuard,
    DefaultGuard,
    LoginPageGuard,
    {
      provide: RouteReuseStrategy,
      useClass: CustomRouteReuseStrategy,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
}
