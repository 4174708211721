import {Injectable} from '@angular/core';
import {catchError, map, switchMap, tap} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';
import {SettingsKeyEnum} from '../models/settings-key.enum';
import {Session} from '../models/session';
import {Router} from '@angular/router';
import {ToastService} from '@ui/services/toast.service';
import {Toast, ToastType} from '@ui/models/toast';
import {User} from '../../user/models/user';
import {ApiService} from '../../app/services/api.service';
import {LocalStorageService} from '../../app/services/local-storage.service';
import {environment} from 'src/environments/environment';
import {Role} from '@ui/enums/user-role.enum';

@Injectable()
export class AuthService {
  redirectAfterLogin = '';
  constructor(
    private apiService: ApiService,
    private localStorageService: LocalStorageService,
    private router: Router,
    private toastService: ToastService,
  ) {
  }

  get currentUser(): User {
    return this.localStorageService.get(SettingsKeyEnum.User);
  }

  set currentUser(user: User) {
    if (user) {
      this.localStorageService.set(SettingsKeyEnum.User, user);
    } else {
      this.deleteLocalStorage();
    }
  }

  private get session(): Session {
    return this.localStorageService.get<Session>(SettingsKeyEnum.AuthTokens);
  }

  private set session(session: Session) {
    if (session) {
      this.localStorageService.set(SettingsKeyEnum.AuthTokens, session);
    } else {
      this.deleteLocalStorage();
    }
  }

  get isAuthenticated(): boolean {
    return !!this.session;
  }

  get jwtToken(): string {
    return this.session?.token;
  }

  get refreshToken(): string {
    return this.session?.refreshToken;
  }

  login(username: string, password: string): Observable<User> {
    return this.apiService.post<Session>(environment.loginUrl, {username, password}).pipe(
      tap((session: Session) => this.session = session),
      map((session: Session) => this.parseJwt(session.token)),
      tap((user: ParsedJwt) => this.preventCustomerLogin(user.roles)),
      switchMap((user: ParsedJwt) => this.apiService.get<User>('/api/users/' + user.id)),
      tap((user: User) => this.handleLoginSuccess(user)),
      catchError(err => {
        this.handleLoginError();
        return throwError(err);
      }),
    );
  }

  async logout(): Promise<void> {
    this.currentUser = null;
    this.session = null;
    return this.handleLogout();
  }

  refreshSession(): Observable<Session> {
    return this.apiService.post(environment.tokenRefreshUrl, {
      refreshToken: this.refreshToken,
    }).pipe(tap((session: Session) => {
      this.session = session;
    }));
  }

  userHasRole(roles: Role[]): boolean {
    if (!this.currentUser?.roles || !roles) {
      return false;
    }
    /* check if some user role matches the role from input */
    return this.currentUser.roles.some(role => roles.includes(role));
  }

  private preventCustomerLogin(userRole: Role[]) {
    if (userRole.includes(Role.Customer)) {
      this.toastService.addToast(new Toast({
        text: `Sie haben nicht die Berechtigung sich einzuloggen.`,
        type: ToastType.Error,
      }));
      this.session = null;
      throw new Error('Access denied. No sufficient rights!');
    }
  }

  private deleteLocalStorage() {
    this.localStorageService.remove(SettingsKeyEnum.AuthTokens);
    this.localStorageService.remove(SettingsKeyEnum.User);
  }

  private parseJwt(token: string): ParsedJwt {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('')
      .map((c) => '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)).join(''));
    return JSON.parse(jsonPayload);
  }

  private async handleLoginSuccess(user: User): Promise<void> {
    this.currentUser = user;
    await this.router.navigate([this.redirectAfterLogin || 'dashboard']);
    this.redirectAfterLogin = '';
    this.toastService.addToast(new Toast({
      text: `Erfolgreich eingeloggt mit "${user.email}"!`,
      type: ToastType.Success,
    }));
  }

  private handleLoginError(): void {
    this.toastService.addToast(new Toast({
      text: 'Login fehlgeschlagen. Überprüfen Sie ihre Login Daten!',
      type: ToastType.Warning,
    }));
  }

  private async handleLogout(): Promise<void> {
    this.toastService.removeAllToasts();
    await this.router.navigate(['/login']);
    this.toastService.addToast(new Toast({
      text: 'Sie wurden erfolgreich ausgeloggt.',
      type: ToastType.Success,
    }));
  }
}

type ParsedJwt = {
  exp: number;
  iat: number;
  id: string;
  roles: Role[];
  username: string;
};
