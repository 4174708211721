import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {AuthService} from '../services/auth.service';
import {ToastService} from '@ui/services/toast.service';
import {Toast, ToastType} from '@ui/models/toast';
import {AuthGuard} from './auth.guard';

@Injectable()
export class DefaultGuard implements CanActivate {
  constructor(
    private router: Router,
    private toastService: ToastService,
    private authService: AuthService
  ) {
  }

  canActivate(): boolean {
    // don't leak info wether a route exists to logged out users
    if (this.authService.isAuthenticated) {
      this.toastService.addToast(new Toast({
        text: `Die eingegebene Adresse existiert nicht`,
        type: ToastType.Warning,
      }));
    }

    this.router.navigate(['']);
    return true;
  }
}
