<div class="input">
  <label class="input__label" for="password-{{controlName}}">{{ label }}<span *ngIf = "hasRequiredValidator">*</span></label>
  <input [autocomplete]="disableAutoComplete? 'new-password': 'on'" class="input__field" [type]="passwordType"
         [formControl]="formControl" id="password-{{controlName}}" (focus)="onInputFocus($event)"/>
  <span class="input__icons">
    <i *ngIf="formControl.invalid && formControl.touched" class="icon-fehler"></i>
    <i *ngIf="formControl.valid && formControl.touched" class="icon-check_circle"></i>
    <i *ngIf="visibilityToggleable" class="icon-{{icon}} input__toggle-show" (click)="togglePassword()"></i>
  </span>
  <p *ngIf="formControl.invalid && formControl.touched" class="input__message--error">{{errorMessage}}</p>
</div>
