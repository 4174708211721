import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {UiModule} from '@ui/ui.module';
import {DataModalComponent} from './components/data-modal/data-modal.component';

@NgModule({
  imports: [
    CommonModule,
    UiModule
  ],
  declarations: [
    DataModalComponent,
  ],
  exports: [
    DataModalComponent
  ]
})
export class UiModalsModule {
}
