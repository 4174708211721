<div class="login">
  <app-sharea-logo class="login__logo"></app-sharea-logo>
  <div class="login__wrapper">
    <h1>Herzlich Willkommen im Betreiberportal</h1>
    <h3>Bitte melden Sie sich an, damit Sie alle Funktionen<br> nutzen können.</h3>
    <div app-card>
      <form class="login__form" [formGroup]="form" (ngSubmit)="onSubmit()">
        <app-input-text type="email" controlName="username" label="E-Mail" errorMessage="Bitte geben Sie Ihre E-Mail ein"></app-input-text>
        <app-input-password controlName="password" label="Passwort" errorMessage="Bitte überprüfen Sie Ihre Eingabe"></app-input-password>
        <div class="button-row">
          <app-button [disabled]="processing" label="Anmelden"></app-button>
          <app-button class="text-button" design="text" label="Passwort vergessen?" (click)="redirectToForgotPasswort()"></app-button>
        </div>
      </form>
    </div>
  </div>
</div>
