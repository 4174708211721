import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {InputFileComponent} from './components/input-file/input-file.component';
import {InputTextComponent} from './components/input-text/input-text.component';
import {DatepickerDateFieldComponent} from './components/datepicker/components/date-field/date-field.component';
import {InputRadioComponent} from './components/input-radio/input-radio.component';
import {SelectComponent} from './components/select/select.component';
import {MultiSelectComponent} from './components/multi-select/multi-select.component';
import {DatepickerDropdownComponent} from './components/datepicker/components/dropdown/dropdown.component';
import {InputCheckboxComponent} from './components/input-checkbox/input-checkbox.component';
import {InputDateComponent} from './components/datepicker/input-date/input-date.component';
import {DatepickerComponent} from './components/datepicker/components/datepicker/datepicker.component';
import {InputPasswordComponent} from './components/input-password/input-password.component';
import {ReactiveFormsModule} from '@angular/forms';
import {UiModule} from '@ui/ui.module';
import {FormsModule} from '@angular/forms';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {TimePickerComponent} from './components/timePicker/components/time-picker/time-picker.component';
import { TimeFieldComponent } from './components/timePicker/components/time-field/time-field.component';
import { InputTimeComponent } from './components/timePicker/input-time/input-time.component';
import {GenericInputComponent} from '@ui-forms/components/generic-input.component';

@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    UiModule,
    FormsModule,
    NgMultiSelectDropDownModule
  ],
  declarations: [
    InputCheckboxComponent,
    InputRadioComponent,
    InputTextComponent,
    InputFileComponent,
    InputPasswordComponent,
    SelectComponent,
    GenericInputComponent,
    MultiSelectComponent,
    InputDateComponent,
    DatepickerDateFieldComponent,
    DatepickerComponent,
    DatepickerDropdownComponent,
    TimePickerComponent,
    TimeFieldComponent,
    InputTimeComponent
  ],
  providers: [],
  exports: [
    InputFileComponent,
    InputCheckboxComponent,
    InputRadioComponent,
    InputTextComponent,
    InputPasswordComponent,
    SelectComponent,
    MultiSelectComponent,
    InputDateComponent,
    InputTimeComponent,
    TimePickerComponent
  ]
})
export class UiFormsModule {
}
