import {FormGroup} from '@angular/forms';

export class Modal {
  title: string;
  content: string;
  component = {
    name: null,
    data: null
  };
  buttonLabels = {
    confirm: '',
    cancel: '',
    reset: ''
  };
  callbacks: {
    confirm: (data?: unknown) => void;
    cancel: () => void;
    reset: () => void;
  } = {
      confirm: null,
      cancel: null,
      reset: null
    };

  constructor(params: ModalParameters) {
    const data = ModalData[params.type];
    this.title = data.title ? data.title : '';
    this.content = data.content ? data.content : null;
    this.component.name = params.component ? params.component : null;
    this.component.data = params.componentData ? params.componentData : null;
    this.buttonLabels.confirm = data.confirmLabel ? data.confirmLabel : 'Ok';
    this.buttonLabels.cancel = data.cancelLabel ? data.cancelLabel : 'Abbrechen';
    this.buttonLabels.reset = data.resetLabel ? data.resetLabel : 'Zurücksetzen';
    this.callbacks.confirm = params.confirmationCallback ? params.confirmationCallback : null;
    this.callbacks.cancel = params.cancelCallback ? params.cancelCallback : null;
  }
}

export interface ModalParameters {
  type: ModalTypes;
  confirmationCallback: (data?: unknown) => void;
  cancelCallback?: (data?: unknown) => void;
  component?: unknown;
  componentData?: any;
}

/**
 * Implement this Interface in all components, rendered dynamically to modals, which need to communicate data to the outside.
 * Makes sure, that all those components have a accessor for getting data back in callback.
 */
export declare interface DataModalComponent<T = unknown> {
  form?: FormGroup;
  /**
   * Accessor for data
   */
  readonly data: T;

  /**
   * Accessor if data is valid
   */
  readonly dataIsValid: boolean;

  /**
   * Optional initial data which can be passed to the DataModalComponent
   */
  initialData?: Partial<T>;

  /**
   * Method to reset data to initial data
   */
  resetData?: () => void;
}

/**
 * All possible modal types.
 * On instantiating a modal the type defines the contents.
 * See further down the const ModalData.
 */
export enum ModalTypes {
  bookingCancelModal = 'bookingCancelModal',
  bookingEndModal = 'bookingEndModal',
  bikeTypeAddModal = 'bikeTypeAddModal',
  hubAddModal = 'hubAddModal',
  hubEditModal = 'hubEditModal',
  hubEditUsageModal = 'hubEditUsageModal',
  lockAddModal = 'lockAddModal',
  lockEditModal = 'lockEditModal',
  providerAddModal = 'providerAddModal',
  providerDeleteModal = 'providerDeleteModal',
  providerEditProfileModal = 'providerEditProfileModal',
  providerEditUsageModal = 'providerEditUsageModal',
  servicePartnerDeleteModal = 'servicePartnerDeleteModal',
  servicePartnerEditProfileModal = 'servicePartnerEditProfileModal',
  servicePartnerAddModal = 'servicePartnerAddModal',
  tariffDeleteModal = 'tariffDeleteModal',
  tariffEditModal = 'tariffEditModal',
  tariffAddModal = 'tariffAddModal',
  userAddModal = 'userAddModal',
  userDeleteModal = 'userDeleteModal',
  userEditProfileModal = 'userEditProfileModal',
  editorEditProfileModal = 'editorEditProfileModal',
  editorDeleteModal = 'editorDeleteModal',
  userEditUsageModal = 'userEditUsageModal',
  userGroupAddModal = 'userGroupAddModal',
  userGroupDeleteModal = 'userGroupDeleteModal',
  userGroupEditModal = 'userGroupEditModal',
  vehicleAddModal = 'vehicleAddModal',
  vehicleDeleteModal = 'vehicleDeleteModal',
  vehicleSelectModal = 'vehicleSelectModal',
  vehicleEditProfileModal = 'vehicleEditProfileModal',
  vehicleEditUsageModal = 'vehicleEditUsageModal',
  vehicleTypeEditProfileModal = 'vehicleTypeEditProfileModal',
  addProviderUserGroupModal = 'addProviderUserGroupModal',
  editorAddModal = 'editorAddModal'
}

/**
 * Depending on ModalTypes, these are the contents to all the modals in the System
 */
const ModalData = {
  bookingCancelModal: {
    title: 'Buchung Stornieren',
    content: 'Bei Stornierung während einer bereits laufenden Miete fallen KEINE Kosten für den Nutzer an. '
      + 'Die Miete wird sofort und ohne Prüfung des Schlosses beendet.',
    cancelLabel: 'Abbrechen',
    confirmLabel: 'Ja, Buchung abbrechen',
    resetLabel: '',
  },
  bookingEndModal: {
    title: 'Buchung Beenden',
    content: 'Bei Beenden während einer bereits laufenden Miete fallen Kosten für den Nutzer an. '
      + 'Die Miete wird sofort und ohne Prüfung des Schlosses beendet.',
    cancelLabel: 'Abbrechen',
    confirmLabel: 'Ja, Buchung beenden',
    resetLabel: '',
  },
  bikeTypeAddModal: {
    title: 'BikeType anlegen',
    content: '',
    cancelLabel: '',
    confirmLabel: 'BikeType speichern',
    resetLabel: '',
  },
  hubAddModal: {
    title: 'Hub anlegen',
    content: '',
    cancelLabel: '',
    confirmLabel: 'Hub speichern',
    resetLabel: '',
  },
  hubEditModal: {
    title: 'Hub bearbeiten',
    content: '',
    cancelLabel: '',
    confirmLabel: 'Änderungen speichern',
    resetLabel: '',
  },
  hubEditUsageModal: {
    title: 'Nutzung bearbeiten',
    content: '',
    cancelLabel: '',
    confirmLabel: 'Änderungen speichern',
    resetLabel: '',
  },
  lockAddModal: {
    title: 'Linka-Schloss anlegen',
    content: '',
    cancelLabel: '',
    confirmLabel: 'Linka-Schloss speichern',
    resetLabel: '',
  },
  lockEditModal: {
    title: 'Linka-Schloss bearbeiten',
    content: '',
    cancelLabel: '',
    confirmLabel: 'Änderungen speichern',
    resetLabel: '',
  },
  providerDeleteModal: {
    title: 'Mandant entfernen',
    content: `Bei Bestätigung wird dieser Mandant unwiderruflich gelöscht.
    Die zum Mandant gehörenden Fahrzeuge, Hubs und Schlösser bleiben erhalten.
    Diese können im Anschluss anderen Mandanten zugewiesen werden.
    Sind Sie sicher, dass Sie diesen Mandanten entfernen wollen?`,
    cancelLabel: 'Abbrechen',
    confirmLabel: 'Ja, Mandant entfernen',
    resetLabel: '',
  },
  providerAddModal: {
    title: 'Mandant anlegen',
    content: '',
    cancelLabel: 'Abbrechen',
    confirmLabel: 'Mandant speichern',
    resetLabel: 'Zurücksetzen',
  },
  providerEditProfileModal: {
    title: 'Profil bearbeiten',
    content: '',
    cancelLabel: '',
    confirmLabel: 'Änderungen speichern',
    resetLabel: '',
  },
  providerEditUsageModal: {
    title: 'Nutzung bearbeiten',
    content: '',
    cancelLabel: '',
    confirmLabel: 'Änderungen speichern',
    resetLabel: '',
  },
  servicePartnerDeleteModal: {
    title: 'Service Partner entfernen',
    content: 'Bei Bestätigung wird dieser Service Partner unwiderruflich gelöscht. Sind Sie sicher?',
    cancelLabel: 'Abbrechen',
    confirmLabel: 'Ja, Service Partner entfernen',
    resetLabel: '',
  },
  servicePartnerEditProfileModal: {
    title: 'Service-Partner bearbeiten',
    content: '',
    cancelLabel: 'Abbrechen',
    confirmLabel: 'Änderungen speichern',
    resetLabel: 'Zurücksetzen',
  },
  servicePartnerAddModal: {
    title: 'Service-Partner anlegen',
    content: '',
    cancelLabel: 'Abbrechen',
    confirmLabel: 'Service-Partner speichern',
    resetLabel: 'Zurücksetzen',
  },
  tariffDeleteModal: {
    title: 'Tarif entfernen',
    content: 'Das Entfernen des Tarifs kann sich auf aktuelle Anfragen sowie bereits gebuchte Fahrzeug mit diesem Tarif auswirken.',
    cancelLabel: 'Abbrechen',
    confirmLabel: 'Ja, Tarif entfernen',
    resetLabel: '',
  },
  tariffEditModal: {
    title: 'Tarif bearbeiten',
    content: '',
    cancelLabel: 'Abbrechen',
    confirmLabel: 'Änderungen speichern',
    resetLabel: 'Zurücksetzen',
  },
  tariffAddModal: {
    title: 'Tarif anlegen',
    content: '',
    cancelLabel: 'Abbrechen',
    confirmLabel: 'Tarif speichern',
    resetLabel: 'Zurücksetzen',
  },
  userAddModal: {
    title: 'Nutzer anlegen',
    content: '',
    cancelLabel: 'Abbrechen',
    confirmLabel: 'Nutzer speichern',
    resetLabel: 'Zurücksetzen',
  },
  userEditProfileModal: {
    title: 'Nutzer bearbeiten',
    content: '',
    cancelLabel: 'Abbrechen',
    confirmLabel: 'Änderungen speichern',
    resetLabel: 'Zurücksetzen',
  },
  editorEditProfileModal: {
    title: 'Mandanten-Admin/Redakteur bearbeiten',
    content: '',
    cancelLabel: 'Abbrechen',
    confirmLabel: 'Änderungen speichern',
    resetLabel: 'Zurücksetzen',
  },
  userEditUsageModal: {
    title: 'Nutzung bearbeiten',
    content: '',
    cancelLabel: 'Abbrechen',
    confirmLabel: 'Änderungen speichern',
    resetLabel: 'Zurücksetzen',
  },
  editorDeleteModal: {
    title: 'Mandanten-Admin/Redakteur entfernen',
    content: 'Bei Bestätigung wird dieser Mandanten-Admin/Redakteur unwiderruflich gelöscht. Sind Sie sicher?',
    cancelLabel: 'Abbrechen',
    confirmLabel: 'Ja, Mandanten-Admin/Redakteur entfernen',
    resetLabel: '',
  },
  userDeleteModal: {
    title: 'Nutzer entfernen',
    content: 'Bei Bestätigung wird dieser Nutzer unwiderruflich gelöscht. Sind Sie sicher?',
    cancelLabel: 'Abbrechen',
    confirmLabel: 'Ja, Nutzer entfernen',
    resetLabel: '',
  },
  userGroupEditModal: {
    title: 'Nutzergruppe bearbeiten',
    content: '',
    cancelLabel: 'Abbrechen',
    confirmLabel: 'Änderungen speichern',
    resetLabel: 'Zurücksetzen',
  },
  userGroupAddModal: {
    title: 'Nutzergruppe hinzufügen',
    content: '',
    cancelLabel: 'Abbrechen',
    confirmLabel: 'Nutzergruppe speichern',
    resetLabel: 'Zurücksetzen',
  },
  userGroupDeleteModal: {
    title: 'Nutzergruppe entfernen',
    content: 'Bei Bestätigung wird dieser Nutzergruppe unwiderruflich gelöscht. Sind Sie sicher?',
    cancelLabel: 'Abbrechen',
    confirmLabel: 'Ja, Nutzergruppe entfernen',
    resetLabel: '',
  },
  vehicleAddModal: {
    title: 'Fahrzeug hinzufügen',
    content: '',
    cancelLabel: 'Abbrechen',
    confirmLabel: 'Fahrzeug speichern',
    resetLabel: 'Zurücksetzen',
  },
  vehicleDeleteModal: {
    title: 'Fahrzeug entfernen',
    content: 'Bei Bestätigung wird dieses Fahrzeug unwiderruflich gelöscht. Sind Sie sicher?',
    cancelLabel: 'Abbrechen',
    confirmLabel: 'Ja, Fahrzeug entfernen',
    resetLabel: '',
  },
  vehicleSelectModal: {
    title: 'Neues Fahrzeug hinzufügen',
    content: '',
    cancelLabel: 'Abbrechen',
    confirmLabel: 'Weiter',
    resetLabel: 'Zurücksetzen',
  },
  vehicleEditProfileModal: {
    title: 'Fahrzeug bearbeiten',
    content: '',
    cancelLabel: 'Abbrechen',
    confirmLabel: 'Änderungen speichern',
    resetLabel: '',
  },
  vehicleEditUsageModal: {
    title: 'Fahrzeug Nutzung bearbeiten',
    content: '',
    cancelLabel: '',
    confirmLabel: 'Änderungen speichern',
    resetLabel: '',
  },
  vehicleTypeEditProfileModal: {
    title: 'Fahrzeugtyp bearbeiten',
    content: '',
    cancelLabel: 'Abbrechen',
    confirmLabel: 'Änderungen speichern',
    resetLabel: '',
  },
  addProviderUserGroupModal: {
    title: 'Mandant und Nutzergruppe wählen',
    content: '',
    cancelLabel: 'Änderungen verwerfen',
    confirmLabel: 'Änderungen speichern',
    resetLabel: 'Zurücksetzen',
  },
  editorAddModal: {
    title: 'Mandanten-Admin/Redakteur Anlegen',
    content: '',
    cancelLabel: 'Abbrechen',
    confirmLabel: 'Mandanten-Admin/Redakteur Speichern',
    resetLabel: 'Zurücksetzen',
  }
};
