<div class="input">
  <span class="input__label">
    <span>{{ label }}</span>
  </span>

  <div class="row no-gap">
    <div class="col-12 col-sm-6 col-xl-4" *ngFor="let radio of radioOptions">
      <label for="rb_{{ controlName }}_{{ radio.key }}">{{radio.label}}</label>
      <input
        class="input__radio"
        type="radio"
        [name]="controlName"
        [formControl]="formControl"
        [value]="radio.key"
        id="rb_{{ controlName }}_{{ radio.key }}"
      />
    </div>
  </div>
</div>
