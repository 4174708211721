import {
  Component,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {DatepickerFormat} from '../../model/datepicker-format.model';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import * as moment from 'moment';
import {DatepickerService} from '../../service/datepicker.service';

@Component({
  selector: 'app-datepicker-date-field',
  templateUrl: './date-field.component.html',
  styleUrls: ['./date-field.component.scss'],
})
export class DatepickerDateFieldComponent implements OnInit, OnChanges {
  @Input() disabled: boolean;
  @Input() dropdownOpen: boolean;
  @Output() dateTyped = new EventEmitter();
  @Output() showError = new EventEmitter<boolean>();
  @ViewChild('day', {static: false}) day: ElementRef;
  @ViewChild('month', {static: false}) month: ElementRef;
  @ViewChild('year', {static: false}) year: ElementRef;

  form: FormGroup;
  date: DatepickerFormat = new DatepickerFormat(null);

  constructor(
    private datepickerService: DatepickerService,
    private fb: FormBuilder,
  ) {
    datepickerService.selectedDate$.subscribe(date => {
      this.date = new DatepickerFormat(date);
      if (date) {
        this.showError.emit(false);
        this.form = this.fb.group({
          day: [{value: this.date.day, disabled: this.disabled}, Validators.required],
          month: [{value: this.date.month, disabled: this.disabled}, Validators.required],
          year: [{value: this.date.year, disabled: this.disabled}, Validators.required],
        });
      } else {
        if (this.form?.valid) {
          this.form = this.fb.group({
            day: [{value: this.date.day, disabled: this.disabled}, Validators.required],
            month: [{value: this.date.month, disabled: this.disabled}, Validators.required],
            year: [{value: this.date.year, disabled: this.disabled}, Validators.required],
          });
        }
      }
    });
  }

  @HostBinding('class.open') get isDropdownOpen(): boolean {
    return this.dropdownOpen;
  }

  @HostBinding('class.disabled') get isDisabled(): boolean {
    return this.disabled;
  }

  @HostListener('click', ['$event']) onHostClick(e): void {
    if (e.target !== this.month.nativeElement && e.target !== this.year.nativeElement) {
      this.day.nativeElement.focus();
    }
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      day: [{value: this.date.day, disabled: this.disabled}, Validators.required],
      month: [{value: this.date.month, disabled: this.disabled}, Validators.required],
      year: [{value: this.date.year, disabled: this.disabled}, Validators.required],
    });
  }

  ngOnChanges(): void {
    if (!this.form) {
      return;
    }
    if (this.disabled) {
      Object.keys(this.form.controls).forEach(key => {
        this.form.controls[key].disable();
      });
    } else {
      Object.keys(this.form.controls).forEach(key => {
        this.form.controls[key].enable();
      });
    }
  }

  onFocus(control: string): void {
    this.form.get(control).setValue('');
  }

  onKeypress(control: string): void {
    const value = this.form.get(control).value;
    if (value && control === 'day' && value.length >= 2) {
      if (this.form.get('month').valid) {
        if (this.form.get('year').valid) {
          this.day.nativeElement.blur();
        } else {
          this.year.nativeElement.focus();
        }
      } else {
        this.month.nativeElement.focus();
      }
    }

    if (value && control === 'month' && value.length >= 2) {
      if (this.form.get('year').valid) {
        this.month.nativeElement.blur();
      } else {
        this.year.nativeElement.focus();
      }
    }

    if (value && control === 'year' && value.length >= 4) {
      this.year.nativeElement.blur();
    }
  }

  onBlur(control: string): void {
    const value = this.form.get(control).value;
    // Handle value of day
    if (control === 'day') {
      this.handleValueOfDay(value);
    }

    // Handle value of month
    if (control === 'month') {
      this.handleValueOfMonth(value);
    }

    // Handle value of year
    if (control === 'year') {
      this.handleValueOfYear(value);
    }
  }

  private handleValueOfDay(value): void {
    if (value && value.length === 1) {
      value = '0' + value;
    }
    if (value && value < 1) {
      value = '01';
    }
    this.form.get('day').setValue(value);
    this.handleTypedDate();
  }

  private handleValueOfMonth(value): void {
    if (value && value.length === 1) {
      value = '0' + value;
    }
    if (value && value < 1) {
      value = '01';
    }
    this.form.get('month').setValue(value);
    this.handleTypedDate();
  }

  private handleValueOfYear(value): void {
    if (value && value.length === 1) {
      value = '200' + value;
    }
    if (value && value.length === 2) {
      value = '20' + value;
    }
    if (value && value.length === 3) {
      value = '2' + value;
    }
    this.form.get('year').setValue(value);
    this.handleTypedDate();
  }

  private handleTypedDate(): void {
    const day = this.form.get('day').value;
    const month = this.form.get('month').value;
    const year = this.form.get('year').value;

    if (this.form.valid) {
      if (moment(year + '-' + month + '-' + day, 'YYYY-MM-DD').isValid()) {
        const date = new Date(year, month - 1, day);
        this.dateTyped.emit({date, hasPartialValue: false, hasValidationError: false});
        this.showError.emit(false);
      } else {
        this.showError.emit(true);
        this.dateTyped.emit({date: null, hasPartialValue: false, hasValidationError: true});
      }
    } else {
      const hasPartialValue = !!day || !!month || !!year;
      this.dateTyped.emit({date: null, hasPartialValue, hasValidationError: false});
    }
  }
}
