<main>
  <app-sharea-logo></app-sharea-logo>
  <div class="imprint-wrapper">
    <div>
      <figure>
        <div><img width="200" height="200" src="assets/images/eurorad-logo.svg" alt="Eurorad Logo"></div>
      </figure>
    </div>

    <div>
      <p>eurorad Deutschland GmbH<br>
        Longericher Str. 2<br>
        50739 Köln</p>
      <p>Telefon: +49 221 17959 670<br>
        Telefax: +49 221 17959 74<br>
        Email: leasing(at)eurorad.de<br>
        Geschäftsführer: Georg Honkomp, Franz Tepe, Arnd Iffland</p>
      <p>Handelsregister: Amtsgericht Köln, HRB 51006<br>
        USt.-ID: DE 228 807 568</p>
      <p>Verantwortlich für den Inhalt: eurorad Deutschland GmbH</p>
    </div>

    <div>
      <h3>Außergerichtliche Streitbeilegung</h3>
      <span>Die Plattform zum europäischen Online-Streitbeilegungsverfahren können Sie mit folgendem Link
        erreichen:&nbsp;<a href="http://ec.europa.eu/consumers/odr/" target="_blank" rel="noopener"><span
            style="color: black;">http://ec.europa.eu/consumers/odr/</span></a> <br>
        Wir weisen darauf hin, dass wir uns gegen eine Teilnahme an Streitbeilegungsverfahren vor
        Verbraucherschlichtungen entschieden haben.</span>
    </div>
    <div>
      <h3>Haftungshinweis</h3>
      <p>Der Seitenbetreiber von eurorad.de ist nicht für die Inhalte fremder Webseiten verantwortlich, die über
        einen Verweis von dieser Webseite aus betreten werden können. Für den Inhalt der verlinkten Seiten sind
        ausschließlich deren Betreiber verantwortlich.</p>

    </div>
    <div>
      <h3>Realisierung</h3>
      <p>Gestaltung: Dunckelfeld<br>
        Umsetzung: Constructiva Solutions<br>
        Texte: Callosum</p>

    </div>
    <div>
      <h3>Google Analytics</h3>
      <p>Diese Website benutzt Google Analytics, einen Webanalysedienst der Google Inc. („Google“). Google
        Analytics verwendet sog. „Cookies“, Textdateien, die auf Ihrem Computer gespeichert werden und die eine
        Analyse der Benutzung der Website durch Sie ermöglichen. Die durch den Cookie erzeugten Informationen
        über Ihre Benutzung dieser Website werden in der Regel an einen Server von Google in den USA übertragen
        und dort gespeichert. Im Falle der Aktivierung der IP-Anonymisierung auf dieser Webseite, wird Ihre
        IP-Adresse von Google jedoch innerhalb von Mitgliedstaaten der Europäischen Union oder in anderen
        Vertragsstaaten des Abkommens über den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen
        wird die volle IP-Adresse an einen Server von Google in den USA übertragen und dort gekürzt.</p>
      <p>Im Auftrag des Betreibers dieser Website wird Google diese Informationen benutzen, um Ihre Nutzung der
        Website auszuwerten, um Reports über die Websiteaktivitäten zusammenzustellen und um weitere mit der
        Websitenutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Websitebetreiber zu
        erbringen.</p>
      <p>Die im Rahmen von Google Analytics von Ihrem Browser übermittelte IP-Adresse wird nicht mit anderen Daten
        von Google zusammengeführt. Sie können die Speicherung der Cookies durch eine entsprechende Einstellung
        Ihrer Browser-Software verhindern; wir weisen Sie jedoch darauf hin, dass Sie in diesem Fall
        gegebenenfalls nicht sämtliche Funktionen dieser Website vollumfänglich werden nutzen können. Sie können
        darüber hinaus die Erfassung der durch das Cookie erzeugten und auf Ihre Nutzung der Website bezogenen
        Daten (inkl. Ihrer IP-Adresse) an Google sowie die Verarbeitung dieser Daten durch Google verhindern,
        indem sie das unter dem folgenden Link (http://tools.google.com/dlpage/gaoptout?hl=de) verfügbare
        Browser-Plugin herunterladen und installieren.</p>
      <p>Nähere Informationen zu Nutzungsbedingungen und Datenschutz finden Sie unter
        www.google.com/analytics/terms/de.html bzw. unter www.google.com/intl/de/analytics/privacyoverview.html.
      </p>
      <p>Wir weisen Sie darauf hin, dass auf dieser Webseite Google Analytics um den Code „gat._anonymizeIp();“
        erweitert wurde, um eine anonymisierte Erfassung von IP-Adressen (sog. IP-Masking) zu gewährleisten.</p>
      <p>Quelle: www.datenschutzbeauftragter-info.de</p>

    </div>
  </div>
</main>
