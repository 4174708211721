import {Pipe, PipeTransform} from '@angular/core';
import {UserProvider} from 'src/modules/user/models/userProvider';

@Pipe({
  name: 'accessProviders'
})
export class AccessProvidersPipe implements PipeTransform {

  transform(accessProviders: UserProvider[]): string {
    return accessProviders?.map(
      accessProvider => `${accessProvider?.provider?.name ?? '-'}: ${accessProvider?.userGroup?.name ?? 'Standard'}`
    ).join(', ');
  }
}
