import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {GenericInputComponent} from '../../generic-input.component';
import {DatePipe} from '@angular/common';
import {ControlContainer} from '@angular/forms';

@Component({
  selector: 'app-input-date',
  templateUrl: './input-date.component.html',
  styleUrls: ['./input-date.component.scss'],
})
export class InputDateComponent extends GenericInputComponent implements OnInit {

  @Input() hasError: boolean;
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Output() hasValidationError = new EventEmitter<boolean>();
  date: Date;

  constructor(
    private datePipe: DatePipe,
    private control: ControlContainer
  ) {
    super(control);
  }
  ngOnInit(): void {
    this.init();
  }

  onDateSelected(value): void {
    if (value) {
      this.date = new Date(this.datePipe.transform(value, 'yyyy-MM-dd'));
      this.setCurrentValue(this.date.toISOString());
    } else {
      this.setCurrentValue('');
    }

    this.formControl.markAsTouched();
  }

  onHasValidationError(value): void {
    this.hasValidationError.emit(value);
  }
}
