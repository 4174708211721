import {NgModule} from '@angular/core';
import {CommonModule, CurrencyPipe} from '@angular/common';
import {IsEmptyPipe} from './pipes/is-empty.pipe';
import {ShareaLogoComponent} from './components/sharea-logo/sharea-logo.component';
import {ButtonComponent} from './components/button/button.component';
import {CardComponent} from './components/card/card.component';
import {LoadingIndicatorComponent} from './components/loading-indicator/loading-indicator.component';
import {DetailStageComponent} from './components/detail-stage/detail-stage.component';
import {BooleanPipe} from './pipes/boolean.pipe';
import {PastTimePipe} from './pipes/past-time.pipe';
import {ToastComponent} from './components/toast/toast.component';
import {LazyImageComponent} from './components/lazy-image/lazy-image.component';
import {UserMenuComponent} from './components/user-menu/user-menu.component';
import {LoadingSpinnerComponent} from './components/loading-spinner/loading-spinner.component';
import {CentsToEuroPipe} from '@ui/pipes/cents-to-euro.pipe';
import {ContentHostDirective} from '@ui/directives/content-host.directive';
import {LockNamesPipe} from './pipes/lock-names.pipe';
import {PhonesPipe} from '@ui/pipes/phones.pipe';
import {AccessProvidersPipe} from './pipes/access-providers.pipe';
import {DisableForRole} from './pipes/disable-for-role.pipe';
import {EnableForRole} from './pipes/enable-for-role.pipe';
import {RouterModule} from '@angular/router';

@NgModule({
  declarations: [
    ButtonComponent,
    IsEmptyPipe,
    ShareaLogoComponent,
    CardComponent,
    LoadingIndicatorComponent,
    DetailStageComponent,
    BooleanPipe,
    PastTimePipe,
    ToastComponent,
    LazyImageComponent,
    UserMenuComponent,
    LoadingSpinnerComponent,
    CentsToEuroPipe,
    ContentHostDirective,
    LockNamesPipe,
    AccessProvidersPipe,
    PhonesPipe,
    DisableForRole,
    EnableForRole
  ],
  imports: [
    CommonModule,
    RouterModule
  ],
  providers: [
    CurrencyPipe,
    IsEmptyPipe,
    CentsToEuroPipe,
    LockNamesPipe,
    AccessProvidersPipe,
    PhonesPipe,
    DisableForRole,
    EnableForRole
  ],
  exports: [
    ButtonComponent,
    ShareaLogoComponent,
    IsEmptyPipe,
    CardComponent,
    LoadingIndicatorComponent,
    DetailStageComponent,
    BooleanPipe,
    PastTimePipe,
    ToastComponent,
    LazyImageComponent,
    UserMenuComponent,
    LoadingSpinnerComponent,
    CentsToEuroPipe,
    ContentHostDirective,
    LockNamesPipe,
    AccessProvidersPipe,
    PhonesPipe,
    DisableForRole,
    EnableForRole
  ],
})
export class UiModule {
}
