<div class="dp-navigation">
  <button (click)="datePickerService.previousYear()" class="icon-doppel_arrow_left" type="button"></button>
  <button (click)="datePickerService.previousMonth()" class="icon-arrow_left" type="button"></button>
  <p class="dp-navigation__label">{{datePickerService.displayMonth()}}</p>
  <button (click)="datePickerService.nextMonth()" class="icon-arrow-right" type="button"></button>
  <button (click)="datePickerService.nextYear()" class="icon-doppel_arrow_right" type="button"></button>
</div>
<div class="dp-month">
  <div class="dp-month__titles">
    <div *ngFor="let title of datePickerService.displayDayTitles()">{{title}}</div>
  </div>
  <div class="dp-month__days">
    <div *ngFor="let week of datePickerService.weeks" class="week">
      <div
        (click)="selectDate($event, day, minDate, maxDate)"
        *ngFor="let day of week.days"
        [ngClass]="{'current-month': datePickerService.isCurrentMonth(day), 'selected-day': datePickerService.isSelectedDay(day), 'disabled': !datePickerService.dayIsAllowed(day, minDate, maxDate)}"
        class="day"
      >{{datePickerService.displayNumberOfDay(day)}}</div>
    </div>
  </div>
</div>
