import {Component, Input, OnInit} from '@angular/core';
import {GenericInputComponent} from '../generic-input.component';
import {ControlContainer} from '@angular/forms';

@Component({
  selector: 'app-input-radio',
  templateUrl: './input-radio.component.html',
  styleUrls: ['../generic-input.component.scss', './input-radio.component.scss'],
})
export class InputRadioComponent extends GenericInputComponent implements OnInit {
  @Input() radioOptions: { key: unknown; label: string }[];

  constructor(private control: ControlContainer) {
    super(control);
  }

  ngOnInit(): void {
    this.init();
  }
}
