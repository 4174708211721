<div *ngIf="user" class="user-menu" (click)="toggleDropdown()">
  <h3>{{userName}}</h3>
  <i [class.open]="isOpen" class="icon-arrow_down"></i>
  <span class="user-role">{{userRole}}</span>
</div>

<div class="user-dropdown" [class.open]="isOpen">
  <div class="user-dropdown__button">
    <i class="icon-abmeldung"></i>
    <span (click)="logout()">Ausloggen</span>
  </div>
</div>
