import {Component, EventEmitter, Input, Output} from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
})
export class ButtonComponent {
  @Input() label = 'Button';
  @Input() icon: string;
  @Input() type: string;
  @Input() disabled = false;
  @Output() $clicked: EventEmitter<void> = new EventEmitter();

  private cssClass = 'button--primary';

  @Input() set design(val) {
    switch (val) {
      case 'primary':
        this.cssClass = 'button--primary';
        break;
      case 'secondary':
        this.cssClass = 'button--secondary';
        break;
      case 'text':
        this.cssClass = 'button--text';
        break;
      default:
        this.cssClass = 'button--primary';
    }
  }

  get design(): string {
    return this.cssClass;
  }

  onClick(): void {
    this.$clicked.emit();
  }
}
