<div class="login">
    <app-sharea-logo class="login__logo"></app-sharea-logo>
    <div class="login__wrapper">
      <h1>Passwort vergessen</h1>
      <div app-card>
        <h4>Bitte geben Sie die E-Mail-Adresse an, mit der Sie angemeldet sind. Wir werden Ihnen anschließend einen Sicherheitscode zuschicken, mit dem Sie das Passwort ändern können.</h4>
        <form class="login__form" [formGroup]="form" (ngSubmit)="onSubmit()">
          <app-input-text type="email" controlName="email" label="E-Mail" errorMessage="Bitte geben Sie Ihre E-Mail ein"></app-input-text>
          <div class="button-row">
            <app-button [disabled]="!form.valid" label="Passwort zurücksetzen"></app-button>
            <app-button class="text-button" design="text" label="Zurück" (click)="goToLoginPage()"></app-button>
          </div>
        </form>
      </div>
    </div>
  </div>
