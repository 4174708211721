import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {catchError} from 'rxjs/operators';
import {ToastService} from '@ui/services/toast.service';
import {Toast, ToastType} from '@ui/models/toast';

@Injectable()
export class ApiService {
  private readonly BASE_URL = environment.API_BASE_URL;

  constructor(
    private http: HttpClient,
    private toastService: ToastService) {
  }

  get<T>(path: string, options = {}): Observable<T> {
    return this.errorHandler(this.http.get<T>(this.BASE_URL + path, options));
  }

  post<T>(path: string, body: unknown, options = {}): Observable<T> {
    return this.errorHandler(this.http.post<T>(this.BASE_URL + path, body, options));
  }

  put<T>(path: string, body: unknown, options = {}): Observable<T> {
    return this.errorHandler(this.http.put<T>(this.BASE_URL + path, body, options));
  }

  patch<T>(path: string, body: unknown, options: { headers?: HttpHeaders } = {}): Observable<T> {
    if (options.headers) {
      options.headers.set('content-type', 'application/merge-patch+json');
    } else {
      options.headers = new HttpHeaders({'content-type': 'application/merge-patch+json'});
    }

    return this.errorHandler(this.http.patch<T>(this.BASE_URL + path, body, options));
  }

  delete<T>(path: string, options = {}): Observable<T> {
    return this.errorHandler(this.http.delete<T>(this.BASE_URL + path, options));
  }

  private errorHandler(observable: Observable<any>): Observable<any> {
    return observable.pipe(
      catchError(error => {
        if (
          error instanceof HttpErrorResponse &&
          error.status >= 400 &&
          error.status <= 500
        ) {
          this.toastService.addToast(new Toast({
            text: 'Wir konnten die Funktion gerade nicht ausführen. Versuchen Sie es noch einmal. Wenn der Fehler ' +
              'weiterhin auftritt, probieren Sie es bitte in ein paar Minuten wieder.',
            type: ToastType.Error,
          }));
        }

        return throwError(error);
      }),
    );
  }
}
