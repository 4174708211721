import { Time } from '@ui/enums/time.enum';

export enum ToastType {
  Info, Warning, Error, Success
}

export type ToastConfig = {
  text: string;
  autoClose?: boolean;
  autoCloseDelay?: number;
  type?: ToastType;
};

export class Toast {
  id: number;
  text: string;
  autoClose: boolean;
  autoCloseDelay: number;
  type: ToastType;

  constructor(config: ToastConfig) {
    this.id = Math.floor(9999999 * Math.random());
    this.text = config.text;
    this.autoClose = config.autoClose ?? true;
    this.autoCloseDelay = config.autoCloseDelay ?? 5 * Time.Second;
    this.type = config.type ?? ToastType.Info;
  }
}
