<div class="modal-background" (click)="cancel()"></div>
<div class="modal">
  <div class="modal-header">
    <button (click)="cancel()" class="modal__close btn btn--primary" aria-label="Schließen">
      <i class="icon-close"></i>
    </button>
    <h2 class="no-margin" [innerHTML]="title"></h2>
  </div>
  <div class="modal-content">
    <div class="modal-content__component" appContentHost></div>
    <p *ngIf="content" [innerHTML]="content"></p>
    <div class="modal-buttons">
      <app-button *ngIf="showResetButton" [label]="resetLabel" design="text" icon="point" (click)="reset()"></app-button>
      <app-button [label]="cancelLabel" design="secondary" (click)="cancel()"></app-button>
      <app-button [label]="confirmLabel" (click)="confirm()"></app-button>
    </div>
  </div>
</div>
