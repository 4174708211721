import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {IDropdownSettings} from 'ng-multiselect-dropdown';
import {GenericInputComponent} from '../generic-input.component';

@Component({
  selector: 'app-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss']
})
export class MultiSelectComponent extends GenericInputComponent implements OnInit {
  @Input() multiSelectLabel: string;
  @Input() dropdownList = [];
  @Input() selectedItems = [];
  @Input() isLoading: boolean;
  @Input() isDisabled: boolean;
  @Input() placeHolderText: string;
  @Input() noDataAvailablePlaceholder: string;
  // TODO: determine types of EventEmitters
  @Output() $itemDeSelect: EventEmitter<any> = new EventEmitter();
  @Output() $itemDeSelectAll: EventEmitter<any> = new EventEmitter();
  @Output() $itemSelect: EventEmitter<any> = new EventEmitter();
  @Output() $itemsSelectAll: EventEmitter<any> = new EventEmitter();

  dropdownSettings: IDropdownSettings = {};


  // prevent closing of filter-modal
  preventClickPropagtion(event) {
    event.stopPropagation();
  }

  ngOnInit() {
    this.init();
    this.dropdownSettings = {
      singleSelection: false,
      idField: 'key',
      textField: 'label',
      selectAllText: 'alle auswählen',
      unSelectAllText: 'alle abwählen',
      noDataAvailablePlaceholderText: this.noDataAvailablePlaceholder,
      allowSearchFilter: false
    };
  }

  onItemSelect(item: any) {
    this.$itemSelect.emit(item);
  }

  onSelectAll(items: any) {
    this.$itemsSelectAll.emit(items);
  }

  onItemDeSelect(item: any) {
    this.$itemDeSelect.emit(item);
  }

  onItemDeSelectAll(item: any) {
    this.$itemDeSelectAll.emit(item);
  }
}
