<button
  [ngClass]="design"
  class="button"
  type="{{ type }}"
  (click)="onClick()"
  [disabled]="disabled"
>
  <i *ngIf="icon" class="icon-{{ icon }}"></i>
  {{ label }}
</button>
