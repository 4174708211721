import {Component, Input} from '@angular/core';
import {MediaService} from '@ui/services/media.service';

@Component({
  selector: 'app-lazy-image',
  templateUrl: './lazy-image.component.html',
  styleUrls: ['./lazy-image.component.scss'],
})
export class LazyImageComponent {

  image: string;
  hasError: boolean;

  constructor(
    private mediaService: MediaService,
  ) {
  }

  @Input() set resourceUrl(value: string) {
    this.image = null;
    this.mediaService.resolveFile(value).subscribe(
      (image: string) => {
        this.image = image;
      },
      () => {
        this.hasError = true;
      },
    );
  }

}
