import {Component, ElementRef, EventEmitter, HostListener, Input, OnInit, Output} from '@angular/core';
import {ControlContainer} from '@angular/forms';
import {GenericInputComponent} from '@ui-forms/components/generic-input.component';
import {TimepickerService} from '../../service/timepicker.service';
import {isNumeric} from 'rxjs/internal-compatibility';

@Component({
  selector: 'app-time-picker',
  templateUrl: './time-picker.component.html',
  styleUrls: ['./time-picker.component.scss'],
  providers: [TimepickerService]
})
export class TimePickerComponent extends GenericInputComponent implements OnInit {
  @Input() label: string;
  @Input() type = 'text';
  @Output() $timeSelected: EventEmitter<any> = new EventEmitter();
  @Input() isRequired: boolean;

  @Input() set date(value: Date) {
    if (value === this.timePickerService.selectedTime) {
      return;
    }
    this.selectedTime = value;
  }

  timeFieldError: boolean;
  open = false;
  hourMaxVal = 24;
  minuteMaxVal = 60;
  hourValue: string;
  minuteValue: string;
  hourValueTemp: number;
  minuteValueTemp: number;
  newHourValue: string;
  newMinuteValue: string;
  selectedTime: Date;

  constructor(
    private control: ControlContainer,
    private element: ElementRef,
    private timePickerService: TimepickerService
  ) {
    super(control);
    timePickerService.selectedTime$.subscribe((date: Date) => {
      this.selectedTime = date;
      if (date) {
        this.$timeSelected.emit(this.selectedTime.toISOString());
      }
    });
  }

  get dropdownIcon(): string {
    return this.open ? 'dropdown-up' : 'dropdown-down';
  }

  @HostListener('document:click', ['$event']) documentClick(event: MouseEvent): void {
    if (event.target !== this.element.nativeElement && !this.element.nativeElement.contains(event.target) && this.open) {
      this.open = false;
    }
  }

  ngOnInit(): void {
    this.init();
    if (this.selectedTime) {
      this.hourValue = this.prefixWithZero(this.selectedTime.getUTCHours());
      this.minuteValue = this.prefixWithZero(this.selectedTime.getUTCMinutes());
      this.timePickerService.setTime(this.hourValue, this.minuteValue);
    } else {
      this.hourValue = 'HH';
      this.minuteValue = 'MM';
    }
  }

  toggle(): void {
    if (this.timeFieldError) {
      return;
    }
    this.open = !this.open;
    if (this.open && this.hourValue && this.minuteValue) {
      if (isNumeric(this.hourValue)) {
        this.hourValueTemp = parseInt(this.hourValue, 10);
      } else {
        this.hourValueTemp = 0;
      }

      if (isNumeric(this.hourValue)) {
        this.minuteValueTemp = parseInt(this.minuteValue, 10);
      } else {
        this.minuteValueTemp = 0;
      }

      this.newHourValue = this.prefixWithZero(this.hourValueTemp);
      this.newMinuteValue = this.prefixWithZero(this.minuteValueTemp);
    }
  }

  hourButtonUpClicked() {
    this.hourValueTemp = (this.hourValueTemp + 1) % this.hourMaxVal;
    this.newHourValue = this.prefixWithZero(this.hourValueTemp);
  }

  hourButtonDownClicked() {
    this.hourValueTemp = (this.hourValueTemp - 1) % this.hourMaxVal;
    if (this.hourValueTemp === -1) {
      this.hourValueTemp = 23;
    }
    this.newHourValue = this.prefixWithZero(this.hourValueTemp);
  }

  minuteButtonUpClicked() {
    this.minuteValueTemp = (this.minuteValueTemp + 1) % this.minuteMaxVal;
    this.newMinuteValue = this.prefixWithZero(this.minuteValueTemp);
  }

  minuteButtonDownClicked() {
    this.minuteValueTemp = (this.minuteValueTemp - 1) % this.minuteMaxVal;
    if (this.minuteValueTemp === -1) {
      this.minuteValueTemp = 59;
    }
    this.newMinuteValue = this.prefixWithZero(this.minuteValueTemp);
  }

  setTime(hourVal: string, minuteVal: string) {
    this.hourValue = hourVal.toString();
    this.minuteValue = minuteVal.toString();
    this.timePickerService.setTime(this.hourValue, this.minuteValue);
    this.$timeSelected.emit(this.selectedTime.toISOString());
    this.open = !this.open;
  }

  closeTimerModal() {
    this.open = false;
  }

  private prefixWithZero(value: number): string {
    return value.toString().padStart(2, '0');
  }
}
