import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import * as moment from 'moment';
import {DatepickerService} from '../../service/datepicker.service';

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  providers: [DatepickerService],
})
export class DatepickerComponent implements OnInit, OnDestroy {

  @Input() label: string;
  @Input() hasError: boolean;
  @Input() disabled: boolean;
  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Input() isRequired: boolean;
  @Output() dateSelected = new EventEmitter<Date>();
  @Output() hasValidationError = new EventEmitter<boolean>();
  toggleDropdown = false;
  dateFieldError: boolean;
  private dateValue: Date;

  constructor(
    public datePickerService: DatepickerService,
    private element: ElementRef,
  ) {
    datePickerService.selectedDate$.subscribe((date: Date) => {
      this.dateValue = date;
      if (date) {
        this.dateSelected.emit(this.date);
      }
    });
  }

  get date(): Date {
    return this.dateValue;
  }

  @Input() set date(value: Date) {
    // prevent the value from being overwritten again and again due to the data binding
    if (value === this.datePickerService.selectedDate) {
      return;
    }

    if (!value) {
      this.datePickerService.resetSelectedDay();
    } else {
      this.datePickerService.selectDay(moment(value));
    }
  }

  get validationErrorMessage(): string {
    return 'Es wurde kein gültiges Datum eingegeben';
  }

  ngOnInit(): void {
    if (this.date) {
      setTimeout(() => {
        this.dateValue = new Date(this.date);
        this.datePickerService.init(this.date);
      });
    } else {
      this.datePickerService.init();
    }
  }

  toggle(state?: boolean): void {
    if (!this.disabled) {
      if (state) {
        this.toggleDropdown = state;
      } else {
        this.toggleDropdown = !this.toggleDropdown;
      }
    }
  }

  onDocumentEvents(e: Event): void {
    const event = e as KeyboardEvent;
    if (event.type === 'click' && event.target !== this.element.nativeElement && !this.element.nativeElement.contains(event.target)) {
      this.toggle(false);
    }
    if (event.type === 'keyup' && event.key === 'Esc') {
      this.toggle(false);
    }
  }

  onResetSelectedDate(event): void {
    event.stopPropagation();  // prevent closing of filter-modal
    this.datePickerService.resetSelectedDay();
    this.dateSelected.emit(null);
    this.dateFieldError = null;
    this.hasValidationError.emit(false);
  }

  onTypedDate(value): void {
    if (value.date) {
      this.datePickerService.setTypedDate(value.date);
    } else {
      if (!this.dateFieldError) {
        this.datePickerService.resetSelectedDay();
      }
      if (!value.hasPartialValue && !value.hasValidationError) {
        this.dateSelected.emit(null);
      }

      if (value.hasValidationError) {
        this.hasValidationError.emit(true);
      }
    }
  }

  onShowError(event): void {
    this.dateFieldError = event;
    this.hasValidationError.emit(true);
  }

  showResetTrigger(): boolean {
    return (this.date || this.dateFieldError) && !this.disabled;
  }

  hasValue(): boolean {
    return !!this.dateSelected;
  }

  showToggle(): boolean {
    return !(this.disabled && this.hasValue());
  }

  ngOnDestroy(): void {
    this.datePickerService.selectedDate = null;
  }
}
