<input
  [formControl]="formControl"
  hidden
  type="text"
>
<app-datepicker
  (dateSelected)="onDateSelected($event)"
  (hasValidationError)="onHasValidationError($event)"
  [date]="currentValue"
  [hasError]="hasError"
  [label]="label"
  [maxDate]="maxDate"
  [minDate]="minDate"
  [isRequired] = "hasRequiredValidator"
></app-datepicker>
