import {enableProdMode} from '@angular/core';
import {platformBrowserDynamic} from '@angular/platform-browser-dynamic';
import {Env} from './environments/env.enum';
import {environment} from './environments/environment';
import {AppModule} from './modules/app/app.module';

if (environment.env !== Env.Development) {
  enableProdMode();
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
