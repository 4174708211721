import {Injectable} from '@angular/core';
import {ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot} from '@angular/router';
import {AuthService} from '../services/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
  ) {
  }

  canActivate(_, state: RouterStateSnapshot ): boolean {
    if (this.authService.isAuthenticated) {
      return true;
    }

    // redirect users where they intially wanted to go after login
    this.authService.redirectAfterLogin = state.url;
    this.router.navigate(['/login']);
    return false;
  }

}
