import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ControlContainer} from '@angular/forms';
import {GenericInputComponent} from '../generic-input.component';

@Component({
  selector: 'app-input-password',
  templateUrl: './input-password.component.html',
  styleUrls: ['../generic-input.component.scss', './input-password.component.scss'],
})
export class InputPasswordComponent extends GenericInputComponent implements OnInit {
  @Input() disableAutoComplete = true; // preventing autofill with autocomplete="new-password"
  @Input() visibilityToggleable = true; // control whether "eye" toggle is visible and functional
  @Output() inputFocus = new EventEmitter<FocusEvent>();
  passwordShown = false;

  get passwordType(): string {
    return (this.passwordShown && this.visibilityToggleable) ? 'text' : 'password';
  }

  get icon(): string {
    return (this.passwordShown && this.visibilityToggleable) ? 'not-show' : 'show';
  }

  constructor(private control: ControlContainer) {
    super(control);
  }

  ngOnInit(): void {
    // init function is inherited from GenericInputComponent
    this.init();
  }

  togglePassword(): void {
    this.passwordShown = !this.passwordShown;
  }

  onInputFocus(event) {
    this.inputFocus.emit(event);
  }
}
