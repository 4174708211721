<div class="input">
  <input
  class="input__checkbox"
  type="checkbox"
  [name]="controlName"
  [formControl]="formControl"
  [value]="value"
  id="cb-{{ controlName }}"
/>
  <label class="input__label" for="cb-{{ controlName }}">
    <span>{{ label }}</span>
  </label>
</div>
