import {Env} from './env.enum';
import packageInfo from '../../package.json';

export const environment = {
  env: Env.Staging,
  API_BASE_URL: 'https://api-stage.sharea.bike',
  MEDIA_HOST: 'https://api-stage.sharea.bike',
  loginUrl: '/api/auth',
  tokenRefreshUrl: '/api/auth/refresh',
  version: packageInfo.version + ' staging'
};
