import {Component, ElementRef, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {User} from '../../../user/models/user';
import {UserRoleLabel} from '@ui/enums/user-role.enum';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
})
export class UserMenuComponent {

  @Input() user: User;
  @Output() logoutClicked: EventEmitter<void> = new EventEmitter();
  isOpen = false;

  constructor(
    private elementRef: ElementRef
  ) {
  }

  get userName(): string {
    if (this.user.firstName && this.user.lastName) {
      return `${this.user.firstName} ${this.user.lastName}`;
    }
    return this.user.email;
  }

  get userRole(): string {
    return `${UserRoleLabel[this.user?.roles?.find(role => role.toString() !== 'ROLE_USER')] || ''}`;
  }

  @HostListener('document:click', ['$event'])
  clicked(event): void {
    if (!this.elementRef.nativeElement.contains(event.target) && this.isOpen) {
      this.toggleDropdown();
    }
  }

  logout(): void {
    this.logoutClicked.emit();
    this.toggleDropdown();
  }

  toggleDropdown(): void {
    this.isOpen = !this.isOpen;
  }
}
