<form [formGroup]="form">
    <input
      #hour
      formControlName="hour"
      placeholder="HH"
      type="text"
      [value] = "hourVal"
      readonly />
    <div class="separator">:</div>
    <input
      #minute
      formControlName="minute"
      placeholder="MM"
      type="text"
      [value] = "minuteVal"
      readonly />
  </form>
  
  
