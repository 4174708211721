<main>
  <app-sharea-logo></app-sharea-logo>
  <div class="privacy-wrapper">
    <h1>Datenschutzerklärung</h1>
    <h2>1. Datenschutz auf einen Blick</h2>
    <h3>Allgemeine Hinweise</h3>
    <p> Die folgenden Hinweise geben einen einfachen Überblick darüber, was mit Ihren
      personenbezogenen Daten passiert, wenn Sie diese App nutzen. Personenbezogene Daten sind alle Daten, mit denen
      Sie persönlich identifiziert werden können. Ausführliche Informationen zum Thema Datenschutz entnehmen Sie
      unserer unter diesem Text aufgeführten Datenschutzerklärung. </p>
    <h3>Datenerfassung durch die Sharea-App</h3>
    <h4> Wer ist verantwortlich für die Datenerfassung durch die Sharea-App? </h4>
    <p> Die Datenverarbeitung erfolgt durch den Verantwortlichen. Dessen Kontaktdaten können Sie
      dem Abschnitt „Hinweis zur Verantwortlichen Stelle“ in dieser Datenschutzerklärung entnehmen. </p>
    <h4>Wie erfassen wir Ihre Daten?</h4>
    <p> Ihre Daten werden zum einen dadurch erhoben, dass Sie uns diese mitteilen. Hierbei kann es
      sich z. B. um Daten handeln, die Sie bei der Registrierung oder in ein Kontaktformular eingeben. <br> Andere
      Daten werden automatisch oder nach Ihrer Einwilligung bei Nutzung der App durch
      unsere IT-Systeme erfasst. Das sind vor allem technische Daten (z. B. Art des Smartphones oder Uhrzeit des
      Zugriffs). Die Erfassung dieser Daten erfolgt automatisch, sobald Sie entsprechende Funktionen nutzen. </p>
    <h4>Wofür nutzen wir Ihre Daten?</h4>
    <p> Ein Teil der Daten wird erhoben, um eine fehlerfreie Bereitstellung der App und die
      angebotenen Sharea-Dienste zu gewährleisten. </p>
    <h4>Welche Rechte haben Sie bezüglich Ihrer Daten?</h4>
    <p> Sie haben jederzeit das Recht, unentgeltlich Auskunft über Herkunft, Empfänger und Zweck
      Ihrer gespeicherten personenbezogenen Daten zu erhalten. Sie haben außerdem ein Recht, die Berichtigung oder
      Löschung dieser Daten zu verlangen. Wenn Sie eine Einwilligung zur Datenverarbeitung erteilt haben, können Sie
      diese Einwilligung jederzeit für die Zukunft widerrufen. Außerdem haben Sie das Recht, unter bestimmten
      Umständen die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. Des Weiteren steht
      Ihnen ein Beschwerderecht bei der zuständigen Aufsichtsbehörde zu. <br> Hierzu sowie zu
      weiteren Fragen zum Thema Datenschutz können Sie sich jederzeit an uns wenden. </p>
    <h2>2. Hosting</h2>
    <h3>Externes Hosting unseres Backends</h3>
    <p> Unsere App nutzt im Hintergrund Backend-Dienste, welche bei einem externen Dienstleister
      gehostet (Hoster) werden. Die personenbezogenen Daten, die dabei erfasst werden, werden auf den Servern des
      Hosters gespeichert. Hierbei kann es sich v. a. um IP-Adressen, Kontaktanfragen, Meta- und Kommunikationsdaten,
      Vertragsdaten, Kontaktdaten, Namen, Reservierungen oder Buchungen und sonstige Daten handeln. <br><br> Der
      Einsatz des Hosters erfolgt zum Zwecke der
      Vertragserfüllung gegenüber unseren potenziellen und bestehenden Kunden (Art. 6 Abs. 1 lit. b DSGVO) und im
      Interesse einer sicheren, schnellen und effizienten Bereitstellung unseres Online-Angebots durch einen
      professionellen Anbieter (Art. 6 Abs. 1 lit. f DSGVO). <br><br> Unser
      Hoster wird Ihre Daten nur insoweit verarbeiten, wie dies zur Erfüllung seiner Leistungspflichten erforderlich
      ist und unsere Weisungen in Bezug auf diese Daten befolgen. <br><br>
      Wir setzen folgenden Hoster ein: <br><br> PlusServer GmbH <br> Hohenzollernring 72 <br> 50672 Köln </p>
    <h4>Auftragsverarbeitung</h4>
    <p> Wir haben einen Vertrag über Auftragsverarbeitung (AVV) mit dem oben genannten Anbieter
      geschlossen. Hierbei handelt es sich um einen datenschutzrechtlich vorgeschriebenen Vertrag, der gewährleistet,
      dass dieser die personenbezogenen Daten unserer Websitebesucher nur nach unseren Weisungen und unter Einhaltung
      der DSGVO verarbeitet. </p>
    <h2>3. Allgemeine Hinweise und Pflichtinformationen</h2>
    <h3>Datenschutz</h3>
    <p> Die Betreiber dieser App nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Wir
      behandeln Ihre personenbezogenen Daten vertraulich und entsprechend den gesetzlichen Datenschutzvorschriften
      sowie dieser Datenschutzerklärung. <br><br>Wenn Sie diese App
      benutzen, werden verschiedene personenbezogene Daten erhoben. Personenbezogene Daten sind Daten, mit denen Sie
      persönlich identifiziert werden können. Die vorliegende Datenschutzerklärung erläutert, welche Daten wir erheben
      und wofür wir sie nutzen. Sie erläutert auch, wie und zu welchem Zweck das geschieht. <br><br>Wir weisen darauf
      hin, dass die Datenübertragung im Internet
      (z. B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen kann. Ein lückenloser Schutz der Daten vor
      dem Zugriff durch Dritte ist nicht möglich. </p>
    <h3>Hinweis zur verantwortlichen Stelle</h3>
    <p> Die verantwortliche Stelle für die Datenverarbeitung auf dieser App ist: <br><br> eurorad Deutschland GmbH <br>
      Longericher Straße 2 <br> 50739 Köln <br><br>
      Telefon: +49 221 17959 670 <br> E-Mail: leasing@eurorad.de <br><br> Verantwortliche Stelle ist die natürliche
      oder juristische Person, die allein oder
      gemeinsam mit anderen über die Zwecke und Mittel der Verarbeitung von personenbezogenen Daten (z. B. Namen,
      E-Mail-Adressen o. Ä.) entscheidet. </p>
    <h3>Speicherdauer</h3>
    <p> Soweit innerhalb dieser Datenschutzerklärung keine speziellere Speicherdauer genannt wurde,
      verbleiben Ihre personenbezogenen Daten bei uns, bis der Zweck für die Datenverarbeitung entfällt. Wenn Sie ein
      berechtigtes Löschersuchen geltend machen oder eine Einwilligung zur Datenverarbeitung widerrufen, werden Ihre
      Daten gelöscht, sofern wir keine anderen rechtlich zulässigen Gründe für die Speicherung Ihrer personenbezogenen
      Daten haben (z. B. steuer- oder handelsrechtliche Aufbewahrungsfristen); im letztgenannten Fall erfolgt die
      Löschung nach Fortfall dieser Gründe. </p>
    <h3>Datenschutzbeauftragter</h3>
    <p> Wir haben für unser Unternehmen einen Datenschutzbeauftragten bestellt. <br><br> Eric Drissler <br> ED Computer
      &amp;
      Design GmbH &amp; Co. KG <br> Lina-Bommer-Weg 4 <br> 51149 Köln <br><br> Telefon: +49 (0) 221 28 88 77 66 <br>
      E-Mail: datenschutz@edcud.de </p>
    <h3>Hinweis zur Datenweitergabe in die USA und sonstige Drittstaaten</h3>
    <p> Wir verwenden unter anderem Tools von Unternehmen mit Sitz in den USA oder sonstigen
      datenschutzrechtlich nicht sicheren Drittstaaten. Wenn diese Tools aktiv sind, können Ihre personenbezogene
      Daten in diese Drittstaaten übertragen und dort verarbeitet werden. Wir weisen darauf hin, dass in diesen
      Ländern kein mit der EU vergleichbares Datenschutzniveau garantiert werden kann. Beispielsweise sind
      US-Unternehmen dazu verpflichtet, personenbezogene Daten an Sicherheitsbehörden herauszugeben, ohne dass Sie als
      Betroffener hiergegen gerichtlich vorgehen könnten. Es kann daher nicht ausgeschlossen werden, dass US-Behörden
      (z. B. Geheimdienste) Ihre auf US-Servern befindlichen Daten zu Überwachungszwecken verarbeiten, auswerten und
      dauerhaft speichern. Wir haben auf diese Verarbeitungstätigkeiten keinen Einfluss. </p>
    <h3>Widerruf Ihrer Einwilligung zur Datenverarbeitung</h3>
    <p> Viele Datenverarbeitungsvorgänge sind nur mit Ihrer ausdrücklichen Einwilligung möglich.
      Sie können eine bereits erteilte Einwilligung jederzeit widerrufen. Die Rechtmäßigkeit der bis zum Widerruf
      erfolgten Datenverarbeitung bleibt vom Widerruf unberührt. </p>
    <h3> Widerspruchsrecht gegen die Datenerhebung in besonderen Fällen sowie gegen Direktwerbung
      (Art. 21 DSGVO) </h3>
    <p> WENN DIE DATENVERARBEITUNG AUF GRUNDLAGE VON ART. 6 ABS. 1 LIT. E ODER F DSGVO ERFOLGT,
      HABEN SIE JEDERZEIT DAS RECHT, AUS GRÜNDEN, DIE SICH AUS IHRER BESONDEREN SITUATION ERGEBEN, GEGEN DIE
      VERARBEITUNG IHRER PERSONENBEZOGENEN DATEN WIDERSPRUCH EINZULEGEN; DIES GILT AUCH FÜR EIN AUF DIESE BESTIMMUNGEN
      GESTÜTZTES PROFILING. DIE JEWEILIGE RECHTSGRUNDLAGE, AUF DENEN EINE VERARBEITUNG BERUHT, ENTNEHMEN SIE DIESER
      DATENSCHUTZERKLÄRUNG. WENN SIE WIDERSPRUCH EINLEGEN, WERDEN WIR IHRE BETROFFENEN PERSONENBEZOGENEN DATEN NICHT
      MEHR VERARBEITEN, ES SEI DENN, WIR KÖNNEN ZWINGENDE SCHUTZWÜRDIGE GRÜNDE FÜR DIE VERARBEITUNG NACHWEISEN, DIE
      IHRE INTERESSEN, RECHTE UND FREIHEITEN ÜBERWIEGEN ODER DIE VERARBEITUNG DIENT DER GELTENDMACHUNG, AUSÜBUNG ODER
      VERTEIDIGUNG VON RECHTSANSPRÜCHEN (WIDERSPRUCH NACH ART. 21 ABS. 1 DSGVO). <br><br> WERDEN IHRE
      PERSONENBEZOGENEN DATEN VERARBEITET, UM DIREKTWERBUNG ZU BETREIBEN, SO
      HABEN SIE DAS RECHT, JEDERZEIT WIDERSPRUCH GEGEN DIE VERARBEITUNG SIE BETREFFENDER PERSONENBEZOGENER DATEN ZUM
      ZWECKE DERARTIGER WERBUNG EINZULEGEN; DIES GILT AUCH FÜR DAS PROFILING, SOWEIT ES MIT SOLCHER DIREKTWERBUNG IN
      VERBINDUNG STEHT. WENN SIE WIDERSPRECHEN, WERDEN IHRE PERSONENBEZOGENEN DATEN ANSCHLIESSEND NICHT MEHR ZUM
      ZWECKE DER DIREKTWERBUNG VERWENDET (WIDERSPRUCH NACH ART. 21 ABS. 2 DSGVO). </p>
    <h3>Beschwerderecht bei der zuständigen Aufsichtsbehörde</h3>
    <p> Im Falle von Verstößen gegen die DSGVO steht den Betroffenen ein Beschwerderecht bei einer
      Aufsichtsbehörde, insbesondere in dem Mitgliedstaat ihres gewöhnlichen Aufenthalts, ihres Arbeitsplatzes oder
      des Orts des mutmaßlichen Verstoßes zu. Das Beschwerderecht besteht unbeschadet anderweitiger
      verwaltungsrechtlicher oder gerichtlicher Rechtsbehelfe. </p>
    <h3>Recht auf Datenübertragbarkeit</h3>
    <p> Sie haben das Recht, Daten, die wir auf Grundlage Ihrer Einwilligung oder in Erfüllung
      eines Vertrags automatisiert verarbeiten, an sich oder an einen Dritten in einem gängigen, maschinenlesbaren
      Format aushändigen zu lassen. Sofern Sie die direkte Übertragung der Daten an einen anderen Verantwortlichen
      verlangen, erfolgt dies nur, soweit es technisch machbar ist. </p>
    <h3>SSL- bzw. TLS-Verschlüsselung</h3>
    <p> Die Kommunikation zwischen App und Backend nutzt aus Sicherheitsgründen und zum Schutz der
      Übertragung vertraulicher Inhalte, wie zum Beispiel Buchungen oder Anliegen, die Sie an uns als Verantwortlicher
      senden, eine SSL- bzw. TLS-Verschlüsselung. </p>
    <h3>Auskunft, Löschung und Berichtigung</h3>
    <p> Sie haben im Rahmen der geltenden gesetzlichen Bestimmungen jederzeit das Recht auf
      unentgeltliche Auskunft über Ihre gespeicherten personenbezogenen Daten, deren Herkunft und Empfänger und den
      Zweck der Datenverarbeitung und ggf. ein Recht auf Berichtigung oder Löschung dieser Daten. Hierzu sowie zu
      weiteren Fragen zum Thema personenbezogene Daten können Sie sich jederzeit an uns wenden. </p>
    <h3>Recht auf Einschränkung der Verarbeitung</h3>
    <p> Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu
      verlangen. Hierzu können Sie sich jederzeit an uns wenden. Das Recht auf Einschränkung der Verarbeitung besteht
      in folgenden Fällen: </p>
    <ul>
      <li> Wenn Sie die Richtigkeit Ihrer bei uns gespeicherten personenbezogenen Daten
        bestreiten, benötigen wir in der Regel Zeit, um dies zu überprüfen. Für die Dauer der Prüfung haben Sie das
        Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. </li>
      <li> Wenn die Verarbeitung Ihrer personenbezogenen Daten unrechtmäßig geschah/geschieht,
        können Sie statt der Löschung die Einschränkung der Datenverarbeitung verlangen. </li>
      <li> Wenn wir Ihre personenbezogenen Daten nicht mehr benötigen, Sie sie jedoch zur
        Ausübung, Verteidigung oder Geltendmachung von Rechtsansprüchen benötigen, haben Sie das Recht, statt der
        Löschung die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten zu verlangen. </li>
      <li> Wenn Sie einen Widerspruch nach Art. 21 Abs. 1 DSGVO eingelegt haben, muss eine
        Abwägung zwischen Ihren und unseren Interessen vorgenommen werden. Solange noch nicht feststeht, wessen
        Interessen überwiegen, haben Sie das Recht, die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten
        zu verlangen. </li>
    </ul>
    <p> Wenn Sie die Verarbeitung Ihrer personenbezogenen Daten eingeschränkt haben, dürfen diese
      Daten – von ihrer Speicherung abgesehen – nur mit Ihrer Einwilligung oder zur Geltendmachung, Ausübung oder
      Verteidigung von Rechtsansprüchen oder zum Schutz der Rechte einer anderen natürlichen oder juristischen Person
      oder aus Gründen eines wichtigen öffentlichen Interesses der Europäischen Union oder eines Mitgliedstaats
      verarbeitet werden. </p>
    <h2>4. Datenerfassung auf Servern für das Backend</h2>
    <h3>Server-Log-Dateien</h3>
    <p> Der Provider des Backends erhebt und speichert automatisch Informationen in so genannten
      Server-Log-Dateien, die die App bei Nutzung automatisch an uns übermittelt. Dies sind u.a.: </p>
    <ul>
      <li>Uhrzeit der Serveranfrage</li>
      <li>IP-Adresse</li>
    </ul>
    <p> Eine Zusammenführung dieser Daten mit anderen Datenquellen wird nicht vorgenommen. <br><br> Die Erfassung dieser
      Daten erfolgt auf Grundlage von Art. 6
      Abs. 1 lit. f DSGVO. Der Verantwortliche hat ein berechtigtes Interesse an der technisch fehlerfreien Funktion
      der App – hierzu müssen die Server-Log-Files erfasst werden. </p>
    <h3>Kontaktformular für Schadensmeldungen</h3>
    <p> Wenn Sie uns per Kontaktformular Schadensmeldungen zukommen lassen, werden Ihre Angaben aus
      dem Anfrageformular inklusive der von Ihnen eventuell dort angegebenen Kontaktdaten zwecks Bearbeitung der
      Schadensmeldung und für den Fall von Anschlussfragen bei uns gespeichert. Ihre personenbezogenen Daten geben wir
      nicht ohne Ihre Einwilligung weiter. <br><br> Die Verarbeitung dieser
      Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines Vertrags
      zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen erforderlich ist. In allen übrigen Fällen beruht
      die Verarbeitung auf unserem berechtigten Interesse an der effektiven Bearbeitung der an uns gerichteten
      Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO) sofern diese
      abgefragt wurde. <br><br> Die von Ihnen im Kontaktformular
      eingegebenen Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung
      widerrufen oder der Zweck für die Datenspeicherung entfällt (z. B. nach abgeschlossener Bearbeitung Ihrer
      Anfrage). Zwingende gesetzliche Bestimmungen – insbesondere Aufbewahrungsfristen – bleiben unberührt. </p>
    <h3>Anfrage per Telefon für PickUp-Service</h3>
    <p> Wenn Sie uns per Telefon kontaktieren, wird Ihre Anfrage inklusive aller daraus
      hervorgehenden personenbezogenen Daten (Name, Anfrage) zum Zwecke der Bearbeitung Ihres Anliegens bei uns
      gespeichert und verarbeitet. Diese Daten geben wir ausschließlich im Rahmen des PickUp-Services an den für den
      PickUp zuständigen Dienstleister weiter. <br><br> Die Verarbeitung
      dieser Daten erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, sofern Ihre Anfrage mit der Erfüllung eines
      Vertrags zusammenhängt oder zur Durchführung vorvertraglicher Maßnahmen erforderlich ist. In allen übrigen
      Fällen beruht die Verarbeitung auf unserem berechtigten Interesse an der effektiven Bearbeitung der an uns
      gerichteten Anfragen (Art. 6 Abs. 1 lit. f DSGVO) oder auf Ihrer Einwilligung (Art. 6 Abs. 1 lit. a DSGVO)
      sofern diese abgefragt wurde. <br><br>Die von Ihnen übermittelten
      Daten verbleiben bei uns, bis Sie uns zur Löschung auffordern, Ihre Einwilligung zur Speicherung widerrufen oder
      der Zweck für die Datenspeicherung entfällt (z. B. nach abgeschlossener Bearbeitung Ihres Anliegens). Zwingende
      gesetzliche Bestimmungen – insbesondere gesetzliche Aufbewahrungsfristen – bleiben unberührt. </p>
    <h3>Registrierung in dieser App</h3>
    <p> Sie können sich in der App registrieren, um zusätzliche Funktionen zu nutzen. Die dazu
      eingegebenen Daten verwenden wir nur zum Zwecke der Nutzung des jeweiligen Angebotes oder Dienstes, für den Sie
      sich registriert haben. Die bei der Registrierung abgefragten Pflichtangaben müssen vollständig angegeben
      werden. Anderenfalls werden wir die Registrierung ablehnen. <br><br>
      Für wichtige Änderungen etwa beim Angebotsumfang oder bei technisch notwendigen Änderungen nutzen wir die bei
      der Registrierung angegebene E-Mail-Adresse, um Sie auf diesem Wege zu informieren. <br><br> Die Verarbeitung
      der bei der Registrierung eingegebenen Daten erfolgt zum Zwecke der
      Durchführung des durch die Registrierung begründeten Nutzungsverhältnisses und ggf. zur Anbahnung weiterer
      Verträge (Art. 6 Abs. 1 lit. b DSGVO). <br><br> Die bei der
      Registrierung erfassten Daten werden von uns gespeichert, solange Sie für die Nutzung dieser App registriert
      sind und werden anschließend gelöscht. Gesetzliche Aufbewahrungsfristen bleiben unberührt. </p>
    <h2>5. Plugins und Tools</h2>
    <h3>Google Maps</h3>
    <p> Diese App nutzt den Kartendienst Google Maps. Anbieter ist die Google Ireland Limited
      („Google“), Gordon House, Barrow Street, Dublin 4, Irland. <br><br>
      Zur Nutzung der Funktionen von Google Maps ist es notwendig, Ihre IP-Adresse zu speichern. Diese Informationen
      werden in der Regel an einen Server von Google in den USA übertragen und dort gespeichert. Der Anbieter dieser
      Seite hat keinen Einfluss auf diese Datenübertragung. Wenn Google Maps aktiviert ist, kann Google zum Zwecke der
      einheitlichen Darstellung der Schriftarten Google Web Fonts verwenden. Beim Aufruf von Google Maps lädt die App
      die benötigten Web Fonts in ihren Cache, um Texte und Schriftarten korrekt anzuzeigen. <br><br> Die Nutzung von
      Google Maps erfolgt im Interesse einer
      ansprechenden Darstellung unserer Online-Angebote und an einer leichten Auffindbarkeit der von uns in der App
      angegebenen Orte. Dies stellt ein berechtigtes Interesse im Sinne von Art. 6 Abs. 1 lit. f DSGVO dar. Sofern
      eine entsprechende Einwilligung abgefragt wurde, erfolgt die Verarbeitung ausschließlich auf Grundlage von Art.
      6 Abs. 1 lit. a DSGVO; die Einwilligung ist jederzeit widerrufbar. <br><br> Die Datenübertragung in die USA wird
      auf die Standardvertragsklauseln der
      EU-Kommission gestützt. Details finden Sie hier: <a
        href="https://privacy.google.com/businesses/gdprcontrollerterms/">https://privacy.google.com/businesses/gdprcontrollerterms/</a>
      und <a
        href="https://privacy.google.com/businesses/gdprcontrollerterms/sccs/">https://privacy.google.com/businesses/gdprcontrollerterms/sccs/</a>.
      <br><br> Mehr Informationen zum Umgang mit Nutzerdaten finden Sie in
      der Datenschutzerklärung von Google: <a
        href="https://policies.google.com/privacy?hl=de">https://policies.google.com/privacy?hl=de</a>
    </p>
    <h2>6. eCommerce und Zahlungsanbieter</h2>
    <h3>Verarbeiten von Daten (Kunden- und Vertragsdaten)</h3>
    <p> Wir erheben, verarbeiten und nutzen personenbezogene Daten nur, soweit sie für die
      Begründung, inhaltliche Ausgestaltung oder Änderung des Rechtsverhältnisses erforderlich sind (Bestandsdaten).
      Dies erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO, der die Verarbeitung von Daten zur Erfüllung eines
      Vertrags oder vorvertraglicher Maßnahmen gestattet. Personenbezogene Daten über die Inanspruchnahme dieser
      Website (Nutzungsdaten) erheben, verarbeiten und nutzen wir nur, soweit dies erforderlich ist, um dem Nutzer die
      Inanspruchnahme des Dienstes zu ermöglichen oder abzurechnen. <br><br>
      Die erhobenen Kundendaten werden nach Abschluss des Auftrags oder Beendigung der Geschäftsbeziehung gelöscht.
      Gesetzliche Aufbewahrungsfristen bleiben unberührt. </p>
    <h3>Datenübermittlung bei Vertragsschluss für Dienstleistungen</h3>
    <p> Wir übermitteln personenbezogene Daten an Dritte nur dann, wenn dies im Rahmen der
      Vertragsabwicklung notwendig ist, etwa an das mit der Zahlungsabwicklung beauftragte Kreditinstitut oder den
      Zahlungsdienstleister. <br><br> Eine weitergehende Übermittlung der
      Daten erfolgt nicht bzw. nur dann, wenn Sie der Übermittlung ausdrücklich zugestimmt haben. Eine Weitergabe
      Ihrer Daten an Dritte ohne ausdrückliche Einwilligung, etwa zu Zwecken der Werbung, erfolgt nicht. <br><br>
      Grundlage für die Datenverarbeitung ist Art. 6 Abs. 1 lit. b
      DSGVO, der die Verarbeitung von Daten zur Erfüllung eines Vertrags oder vorvertraglicher Maßnahmen gestattet.
    </p>
    <h3>Zahlungsdienste</h3>
    <p> Wir binden Zahlungsdienste von Drittunternehmen in die App ein. Wenn Sie eine Buchung bei
      uns tätigen, werden Ihre Zahlungsdaten (z. B. Name, Zahlungssumme, Kontoverbindung, Kreditkartennummer) vom
      Zahlungsdienstleister zum Zwecke der Zahlungsabwicklung verarbeitet. Für diese Transaktionen gelten die
      jeweiligen Vertrags- und Datenschutzbestimmungen der jeweiligen Anbieter. Der Einsatz der Zahlungsdienstleister
      erfolgt auf Grundlage von Art. 6 Abs. 1 lit. b DSGVO (Vertragsabwicklung) sowie im Interesse eines möglichst
      reibungslosen, komfortablen und sicheren Zahlungsvorgangs (Art. 6 Abs. 1 lit. f DSGVO). Soweit für bestimmte
      Handlungen Ihre Einwilligung abgefragt wird, ist Art. 6 Abs. 1 lit. a DSGVO Rechtsgrundlage der
      Datenverarbeitung; Einwilligungen sind jederzeit für die Zukunft widerrufbar. <br><br> Folgende Zahlungsdienste
      / Zahlungsdienstleister setzen wir im Rahmen dieser Website
      ein: </p>
    <h4>PayPal</h4>
    <p> Anbieter dieses Zahlungsdienstes ist PayPal (Europe) S.à.r.l. et Cie, S.C.A., 22-24
      Boulevard Royal, L-2449 Luxembourg (im Folgenden „PayPal“). <br><br>
      Die Datenübertragung in die USA wird auf die Standardvertragsklauseln der EU-Kommission gestützt. Details finden
      Sie hier: <a
        href="https://www.paypal.com/de/webapps/mpp/ua/pocpsa-full">https://www.paypal.com/de/webapps/mpp/ua/pocpsa-full</a><br><br>
      Details entnehmen Sie der Datenschutzerklärung von PayPal: <a
        href="https://www.paypal.com/de/webapps/mpp/ua/privacy-full">https://www.paypal.com/de/webapps/mpp/ua/privacy-full</a>
    </p>
  </div>
</main>
