import {Pipe, PipeTransform} from '@angular/core';
import {Lock} from 'src/modules/locks/models/lock';

@Pipe({
  name: 'lockNames'
})
export class LockNamesPipe implements PipeTransform {

  transform(currentLocks: Lock[]): string {
    return `${currentLocks?.map(lock => lock?.name).join(',') || '-'}`;
  }

}
