import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AuthService} from '../../services/auth.service';
import {Router} from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  form: FormGroup;
  processing = false;

  constructor(
    private formBuilder: FormBuilder,
    private authService: AuthService,
    private router: Router
  ) {
  }

  ngOnInit(): void {
    this.initFormGroup();
  }

  onSubmit(): void {
    if (!this.form.valid) {
      return;
    }
    this.processing = true;
    const {username, password} = this.form.value;
    this.authService.login(username, password).subscribe({next: () => this.processing = false, error: () => this.processing = false});
  }

  redirectToForgotPasswort() {
    this.router.navigate(['passwort-vergessen']);
  }

  private initFormGroup(): void {
    this.form = this.formBuilder.group({
      username: ['', [Validators.required, Validators.email]],
      password: ['', [Validators.required]],
    });
  }
}
