import {Component, EventEmitter, Input, Output, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-card, [app-card]',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent {
  @Input() title: string;
  @Input() editable = false;
  @Output() editClicked: EventEmitter<void> = new EventEmitter();

  onEditClicked(): void {
    this.editClicked.emit();
  }
}
