import {FormGroup} from '@angular/forms';

export class AuthValidator {
  static passwordValidation(formGroup: FormGroup) {
    const password = formGroup.controls.password.value;
    const passwordRepeat = formGroup.controls.passwordRepeat.value;

    const hasMinLength = password.length >= 8;
    const hasCapital = (/(.*[A-Z].*)/.test(password));
    const hasLower = (/(.*[a-z].*)/.test(password));
    const hasNumber = (/(.*\d.*)/.test(password));
    const passwordMatch = passwordRepeat === password;

    // if conditions are all valid, return null
    if ([hasMinLength, hasCapital, hasLower, hasNumber, passwordMatch, password.length].every(item => item)) {
      return null;
    }
    return {
      hasMinLength: !hasMinLength,
      hasCapital: !hasCapital,
      hasLower: !hasLower,
      hasNumber: !hasNumber,
      passwordMatch: !passwordMatch,
    };
  }
}
