export class DatepickerFormat {
  day: string;
  month: string;
  year: string;

  constructor(date: Date) {
    if (date) {
      this.initFormat(date);
    }
  }

  private initFormat(date: Date): void {
    this.day = ('0' + date.getDate()).slice(-2);
    this.month = ('0' + (date.getMonth() + 1)).slice(-2);
    this.year = date.getFullYear().toString();
  }
}
