import {Component, OnInit} from '@angular/core';
import {GenericInputComponent} from '@ui-forms/components/generic-input.component';

@Component({
  selector: 'app-input-time',
  templateUrl: './input-time.component.html',
  styleUrls: ['./input-time.component.scss']
})
export class InputTimeComponent extends GenericInputComponent implements OnInit {

  ngOnInit(): void {
    this.init();
  }

  onDateSelected(value): void {
    if (value) {
      this.setCurrentValue(value);
    } else {
      this.setCurrentValue('');
    }

    this.formControl.markAsTouched();
  }
}
