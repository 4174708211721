import {Injectable} from '@angular/core';
import * as moment from 'moment';
import {Moment} from 'moment';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable()
export class DatepickerService {

  moment: Moment = null;
  private readonly weeksSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  private readonly selectedDateSubject = new BehaviorSubject<Date>(null);

  constructor() {
    moment.locale('de', {
      months: 'Januar_Februar_März_April_Mai_Juni_Juli_August_September_Oktober_November_Dezember'.split('_'),
      monthsParseExact: true,
      weekdays: 'Montag_Dienstag_Mittwoch_Donnerstag_Freitag_Samstag_Sonntag'.split('_'),
      weekdaysMin: 'Mo_Di_Mi_Do_Fr_Sa_So'.split('_'),
      weekdaysParseExact: true,
      week: {
        dow: 1, // Monday is the first day of the week.
        doy: 4,  // Used to determine first week of the year.
      },
    });
  }

  get selectedDate$(): Observable<Date> {
    return this.selectedDateSubject.asObservable();
  }

  get selectedDate(): Date {
    return this.selectedDateSubject.getValue();
  }

  set selectedDate(date: Date) {
    this.selectedDateSubject.next(date);
  }

  get weeks(): any {
    return this.weeksSubject.getValue();
  }

  set weeks(w: any) {
    this.weeksSubject.next(w);
  }

  init(date?: Date): void {
    if (date) {
      this.moment = moment(date);
      this.selectedDate = date;
    } else {
      this.moment = moment();
    }
    this.renderCalendar();
  }

  renderCalendar(): void {
    const startWeek = this.moment.startOf('month').week();
    const endWeek = startWeek + 5;

    const w = [];
    for (let week = startWeek; week <= endWeek; week++) {
      w.push({
        w: week,
        days: Array(7).fill(0).map((n, i) => moment(this.moment).week(week).startOf('week').clone().add(n + i, 'day')),
      });
    }
    this.weeks = w;
  }

  selectDay(date: Moment): void {
    this.moment = date;
    this.selectedDate = date.toDate();
    this.renderCalendar();
  }

  setTypedDate(date: Date): void {
    this.moment = moment(date);
    this.selectedDate = date;
    this.renderCalendar();
  }

  nextMonth(): void {
    this.moment = this.moment.add(1, 'M').startOf('month');
    this.renderCalendar();
  }

  nextYear(): void {
    this.moment = this.moment.add(1, 'y').startOf('month');
    this.renderCalendar();
  }

  previousMonth(): void {
    this.moment = this.moment.subtract(1, 'M').startOf('month');
    this.renderCalendar();
  }

  previousYear(): void {
    this.moment = this.moment.subtract(1, 'y').startOf('month');
    this.renderCalendar();
  }

  displayMonth(): string {
    const month = this.moment.format('MMMM');
    const year = this.moment.format('YYYY');
    return `${month} ${year}`;
  }

  displayDayTitles(): string[] {
    return moment.weekdaysMin();
  }

  displayNumberOfDay(day): string {
    return day.date().toString();
  }

  isCurrentMonth(day): boolean {
    return this.moment ? day.month() === this.moment.month() : false;
  }

  isSelectedDay(day): boolean {
    return this.selectedDate ?
      day.toDate().setHours(0, 0, 0, 0) === this.selectedDate.setHours(0, 0, 0, 0) :
      false;
  }

  resetSelectedDay(): void {
    this.selectedDate = null;
  }

  dayIsAllowed(day, minDate?, maxDate?): boolean {
    if (minDate && (day - minDate !== 0 && !day.isAfter(minDate))) {
      return false;
    }

    return !(maxDate && day.isAfter(maxDate));
  }
}
