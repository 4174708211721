import {Injectable} from '@angular/core';
import {CanActivate, Router} from '@angular/router';
import {AuthService} from '../services/auth.service';
import {ToastService} from '@ui/services/toast.service';
import {Toast, ToastType} from '@ui/models/toast';

@Injectable()
export class LoginPageGuard implements CanActivate {
  constructor(
    private authService: AuthService,
    private router: Router,
    private toastService: ToastService
  ) {
  }

  canActivate(): boolean {
    if (this.authService.isAuthenticated) {
      this.router.navigate(['/dashboard']);
      this.toastService.addToast(new Toast({
        text: `Sie wurden automatisch auf das Dashboard weitergeleitet`,
        type: ToastType.Success,
      }));
      return false;
    }
    return true;
  }
}
