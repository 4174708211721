import {Component, Input, OnInit} from '@angular/core';
import {interval, Observable} from 'rxjs';
import {map} from 'rxjs/operators';

@Component({
  selector: 'app-loading-indicator',
  templateUrl: './loading-indicator.component.html',
  styleUrls: ['./loading-indicator.component.scss']
})
export class LoadingIndicatorComponent implements OnInit {
  @Input() prefixText = 'Daten werden geladen';
  dots: Observable<string>;

  ngOnInit(): void {
    this.dots = interval(500).pipe(map(i => {
      const dotNumber = i % 3;
      return '.' + Array(dotNumber).fill('.').join('');
    }));
  }
}
