import { Component, OnInit } from '@angular/core';
import {FormGroup, FormBuilder, Validators} from '@angular/forms';
import {ApiService} from '../../services/api.service';
import {Router} from '@angular/router';
import {LocalStorageService} from '../../services/local-storage.service';
import {ToastService} from '@ui/services/toast.service';
import {Toast, ToastType} from '@ui/models/toast';
import {AuthValidator} from '../../services/auth.validator';
import {SettingsKeyEnum} from '../../models/settings-key.enum';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  form: FormGroup;

  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private router: Router,
    private localStorageService: LocalStorageService,
    private toastService: ToastService
    ) {}


    get storedEmail(): string {
      const email = this.localStorageService.get<string>(SettingsKeyEnum.PasswordResetEmail);
      if (email) {
        return this.maskEmail(email);
      }
      return '';
    }


  ngOnInit(): void {
    this.initFormGroup();
  }


  onSubmit() {
    const body = {
      password: this.form.controls.password.value,
      token: this.form.controls.token.value,
      email: this.localStorageService.get<string>(SettingsKeyEnum.PasswordResetEmail)
    };
     this.apiService.post('/api/users/password/reset', body).subscribe(() => {
       this.localStorageService.remove(SettingsKeyEnum.PasswordResetEmail);
       this.router.navigate(['login'], { replaceUrl: true });
       this.toastService.addToast(new Toast({
        text: `Passwort wurde erfolgreich geändert`,
        type: ToastType.Success,
      }));
      },
      err => {
        this.toastService.addToast(new Toast({
          text: `Passwort konnte nicht geändert werden`,
          type: ToastType.Error,
        }));
      });
  }

  goToLoginPage() {
    this.router.navigate(['login']);
  }

  private initFormGroup(): void {
    this.form = this.formBuilder.group({
      token: ['', [Validators.required]],
      password: ['', [Validators.required]],
      passwordRepeat: ['', [Validators.required]],
    }, {validators: [AuthValidator.passwordValidation]});
  }

  private maskEmail(email: string) {
    return `${email.substr(0, 3)}******@${email.split('@').pop()}`;
  }
}
