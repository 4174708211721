import {Pipe, PipeTransform} from '@angular/core';
import {Time} from '../enums/time.enum';

@Pipe({
  name: 'pastTime'
})
export class PastTimePipe implements PipeTransform {

  transform(value: unknown): string | unknown {
    if (typeof value !== 'string' || !Date.parse(value)) {
      return value;
    }

    const diff = Math.floor((new Date()).getTime() - (new Date(value)).getTime());
    const diffInMinutes = Math.floor(diff / Time.Minute);
    const diffInHours = Math.floor(diff / Time.Hour);
    const diffInDays = Math.floor(diff / Time.Day);

    /* difference is less than an hour*/
    if (diffInMinutes < 60) {
      return `${diffInMinutes} ${(diffInMinutes === 1) ? 'Minute' : 'Minuten'}`;
    }

    /* difference is less than a day*/
    if (diffInHours < 24) {
      return `${diffInHours} ${(diffInHours === 1) ? 'Stunde' : 'Stunden'}`;
    }

    return `${diffInDays} ${(diffInDays === 1) ? 'Tag' : 'Tagen'}`;
  }

}
