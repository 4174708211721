import { Pipe, PipeTransform } from '@angular/core';
import {Phone} from '@ui/interfaces/phone';

@Pipe({
  name: 'phones'
})
export class PhonesPipe implements PipeTransform {

  transform(value: Phone[]): string {
    return value?.map(phone => phone.callNumber).join(', ') || '-';
  }
}
