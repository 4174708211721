import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';

@Injectable()
export class TimepickerService {

  private readonly selectedTimeSubject = new BehaviorSubject<Date>(null);

  get selectedTime$(): Observable<Date> {
    return this.selectedTimeSubject.asObservable();
  }

  get selectedTime(): Date {
    return this.selectedTimeSubject.getValue();
  }

  set selectedTime(date: Date) {
    this.selectedTimeSubject.next(date);
  }

  setTime(hourValue, minuteValue) {
    this.selectedTime = new Date(Date.UTC(2000, 1, 1, hourValue, minuteValue));
  }
}
