import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, ControlContainer} from '@angular/forms';
import {GenericInputComponent} from '../generic-input.component';

@Component({
  selector: 'app-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: ['./input-text.component.scss', '../generic-input.component.scss'],
})
export class InputTextComponent extends GenericInputComponent implements OnInit {
  @Input() type = 'text';

  constructor(private control: ControlContainer) {
    super(control);
  }

  ngOnInit(): void {
    this.init();
  }
}
