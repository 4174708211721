<p class="input__label">{{multiSelectLabel}}<span *ngIf="hasRequiredValidator">*</span></p>
<ng-multiselect-dropdown
  [placeholder]="placeHolderText"
  [settings]="dropdownSettings"
  [data]="dropdownList"
  (click)="preventClickPropagtion($event)"
  [class.select-disable]="isDisabled"
  [(ngModel)]="selectedItems"
  (onSelect)="onItemSelect($event)"
  (onSelectAll)="onSelectAll($event)"
  (onDeSelect)="onItemDeSelect($event)"
  (onDeSelectAll)="onItemDeSelectAll($event)"
>
</ng-multiselect-dropdown>
<app-loading-spinner class="multiSelectSpinner" *ngIf="isLoading"></app-loading-spinner>
