import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'isEmpty',
})
export class IsEmptyPipe implements PipeTransform {

  transform(value: unknown, replaceText: string = '-'): string | unknown {
    if (typeof value === 'string') {
      value = value.trim();
    }
    if (typeof value === 'undefined' || !value) {
      return replaceText;
    }
    return value;
  }

}
