import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'boolean'
})
export class BooleanPipe implements PipeTransform {
  transform(value: boolean, ...args: unknown[]): unknown {
    const trueValue = args?.[0] ?? 'Ja';
    const falseValue = args?.[1] ?? 'Nein';
    return value ? trueValue : falseValue;
  }
}
