import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';

@Component({
  selector: 'app-detail-stage',
  templateUrl: './detail-stage.component.html',
  styleUrls: ['./detail-stage.component.scss'],
})
export class DetailStageComponent implements OnInit {
  @Input() title: string;
  @Input() subtitle: string;
  @Input() overviewlinkText: string;
  @Input() animated = false;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
  }

  ngOnInit(): void { }

  goToOverviewPage(): void {
    const url = this.router.routerState.snapshot.url.split('/');
    if (url.some(current => ['vehicles', 'vehicletypes'].includes(current))) {
      this.router.navigate(['../../'], {relativeTo: this.activatedRoute});
      return;
    }
    this.router.navigate(['../'], {relativeTo: this.activatedRoute});
  }
}
