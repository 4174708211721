import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-time-field',
  templateUrl: './time-field.component.html',
  styleUrls: ['./time-field.component.scss']
})
export class TimeFieldComponent implements OnInit {
  @Input() hourVal;
  @Input() minuteVal;
  @ViewChild('hour', {static: false}) hour: ElementRef;
  @ViewChild('minute', {static: false}) minute: ElementRef;
  form: FormGroup;

  constructor(
    private fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      hour: [this.hourVal, Validators.required],
      minute: [this.minuteVal, Validators.required],
    });
  }
}
