<div class="input">
  <label *ngIf="label" class="input__label" for="text-{{controlName}}">{{ label }}<span *ngIf = "isRequired">*</span></label>
 <div class="input__field">
    <app-time-field 
      [hourVal]  = "hourValue"  
      [minuteVal] = "minuteValue" 
     >
    </app-time-field>
  <span class="input__icons">
    <div (click)="toggle()" [class.open]="open"><i class="icon-clock"></i></div>
  </span>
  <div class="input__field__timer" [class.open]="open">
    <div class="close-button" (click)="closeTimerModal()">
      <i class="icon-close"></i>
    </div>
    <div class="input__field__timer-box">
      <div class="timer-items">
        <div class="timer-arrows">
          <span class="icon-arrow_up" (click)= "hourButtonUpClicked()"></span>
        </div>
        <label class="timer-contents">
          <input class="timer-value" [value] = "newHourValue" readonly/>
        </label>
        <div class="timer-arrows">
          <span class="icon-arrow_down" (click)= "hourButtonDownClicked()"></span>
        </div>
      </div>
      <div class="middle">
        <span>
          :
        </span>
      </div>
      <div class="timer-items">
        <div class="timer-arrows">
          <span class="icon-arrow_up" (click)= "minuteButtonUpClicked()"></span>
        </div>
        <label class="timer-contents">
          <input class="timer-value" [value] = "newMinuteValue" readonly/>
        </label>
        <div class="timer-arrows">
          <span class="icon-arrow_down" (click)= "minuteButtonDownClicked()"></span>
        </div>
      </div>
    </div>
    <div class="select-button">
      <app-button label="Auswählen" (click)="setTime(newHourValue, newMinuteValue)"></app-button>
    </div>
  </div>
</div>
</div>
