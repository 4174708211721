import {ComponentFactoryResolver, ComponentRef, Injectable, ViewContainerRef} from '@angular/core';
import {FormGroup} from '@angular/forms';
import {Modal, DataModalComponent, ModalParameters} from '../models/modal';

@Injectable({
  providedIn: 'root'
})
export class DataModalService {

  private modal: Modal;
  private container: ViewContainerRef;
  private componentRef: ComponentRef<DataModalComponent>;
  private modalIsOpen = false;

  constructor(private resolver: ComponentFactoryResolver) {
  }

  get isOpen(): boolean {
    return this.modalIsOpen;
  }

  get currentModal(): Modal {
    return this.modal;
  }

  get showResetButton(): boolean {
    const component = this.componentRef?.instance;
    return !!component && !!component.resetData;
  }

  /**
   * Register reference to template of ModalComponent, in case modal needs to render components dynamically (instead of
   * content-string)
   *
   * @param container
   */
  init(container: ViewContainerRef): void {
    this.container = container;
  }

  /**
   * Open a modal via specific type and callbacks.
   *
   * @param params : ModalParameters passed in parameters
   */
  openModal(params: ModalParameters) {
    this.modal = new Modal(params);
    if (this.modal.component.name) {
      this.loadComponent(this.container, this.modal.component.name, this.modal.component.data);
    }
    this.modalIsOpen = true;
    if (!document.body.classList.contains('overflow-hidden')) {
      document.body.classList.add('overflow-hidden');
    }
  }

  /**
   * If cancel callback exists it will be called. Otherwise just closing modal.
   *
   */
  cancelModal() {
    if (this.modal.callbacks.cancel) {
      this.closeModal();
      this.modal.callbacks.cancel();
    } else {
      this.closeModal();
    }
  }

  /**
   * If confirmation callback exists it will be called. Otherwise just closing modal.
   *
   */
  confirmModal() {
    //If we have a reference to a component, we want to send data to the confirm-callback
    if (this.componentRef) {
      const childComponentInstance = this.componentRef.instance;
      this.markFormGroupTouched(childComponentInstance.form);
      //Check if component tells us that data is valid
      if (!childComponentInstance.dataIsValid) {
        return;
      }

      //Close modal first because eventually the callback triggers opening another modal, preventing race conditions
      this.closeModal();
      //Send data to confirm-callback
      this.modal.callbacks.confirm(childComponentInstance.data);
    }
    //If we have a callback but no component, we just call the callback
    else if (!this.componentRef && this.modal.callbacks.confirm) {
      //Close modal first because eventually the callback triggers opening another modal, preventing race conditions
      this.closeModal();
      this.modal.callbacks.confirm();
    } else {
      //If nothing else should happen we just close the modal
      this.closeModal();
    }
  }

  /**
   * Reset the form within the component
   */
  resetModalComponent(): void {
    // If there is a component
    if (this.componentRef) {
      this.componentRef.instance.resetData();
    }
  }

  private markFormGroupTouched(formGroup: FormGroup) {
    if (!formGroup?.controls) {
      return;
    };
    Object.values(formGroup.controls).map(control => {
      if (control instanceof FormGroup) {
        this.markFormGroupTouched(control);
      } else {
        control.markAsTouched();
      }
    });
  }

  /**
   * Set local variable of modal to null, results in closing modal
   *
   * @privat
   */
  private closeModal(): void {
    this.container.clear();
    if (document.body.classList.contains('overflow-hidden')) {
      document.body.classList.remove('overflow-hidden');
    }
    this.modalIsOpen = false;
  }

  /**
   * Loads a component to modal dynamically
   *
   * @param container target dom container to render component to
   * @param component classname of component to instantiate
   * @param componentData data to be passed to component
   * @private
   */
  private loadComponent(container: ViewContainerRef, component, componentData?) {
    const componentFactory = this.resolver.resolveComponentFactory<DataModalComponent>(component);
    this.componentRef = container.createComponent(componentFactory);
    if (componentData) {
      this.componentRef.instance.initialData = componentData;
    }
  }
}
