import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../services/auth.service';
import {LabelledRoute, routes} from '../../routes';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  allRoutes: LabelledRoute[];

  constructor(
    private authService: AuthService
  ) {
  }

  get currentUser() {
    return this.authService.currentUser;
  }

  get isLoggedIn(): boolean {
    return this.authService.isAuthenticated && !!this.authService.currentUser;
  }

  ngOnInit() {
    this.allRoutes = routes
      .find(route => route.isNavigationItem)?.children
      .filter(childRoute => childRoute.path.length);
  }

  onLogoutClicked() {
    this.authService.logout();
  }
}
