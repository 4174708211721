import {Injectable} from '@angular/core';
import {NavigationExtras, Router} from '@angular/router';
import {VehicleType} from 'src/modules/vehicle/models/vehicle-type';
import {Vehicle} from '../../vehicle/models/vehicle';

@Injectable()
export class NavigationService {

  constructor(
    private router: Router,
  ) {
  }

  navigate(commands: string[], extras?: NavigationExtras): Promise<boolean> {
    return this.router.navigate(commands, extras);
  }

  navigateToVehicle(vehicle: Vehicle): Promise<boolean> {
    return this.navigate(['vehicles', vehicle['@type'].toLowerCase(), vehicle.id]);
  }

  navigateToVehicleType(vehicleType: VehicleType): Promise<boolean> {
    return this.navigate(['vehicletypes', vehicleType['@type'].toLowerCase(), vehicleType.id]);
  }
}
