import {Pipe, PipeTransform} from '@angular/core';
import {CurrencyPipe, registerLocaleData} from '@angular/common';
import deLocale from '@angular/common/locales/de';

registerLocaleData(deLocale, 'de');

@Pipe({
  name: 'centsToEuro',
})
export class CentsToEuroPipe implements PipeTransform {
  constructor(
    private currencyPipe: CurrencyPipe
  ) {
  }

  transform(value: number): string {
    if (typeof value === 'undefined' || typeof value !== 'number') {
      return '-';
    }
    return this.currencyPipe.transform(value / 100, 'EUR', 'symbol', '1.2-2', 'de');
  }
}
