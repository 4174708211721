export enum UserRoleLabel {
  ROLE_SUPER_ADMIN = 'Super Admin',
  ROLE_ADMIN = 'Admin',
  ROLE_CURATOR = 'Mandanten-Admin',
  ROLE_EDITOR = 'Redakteur',
  ROLE_CUSTOMER = 'Kunde',
  ROLE_RETAILER = 'Servicepartner'
}

export enum Role {
  Admin = 'ROLE_ADMIN',
  Curator = 'ROLE_CURATOR',
  Editor = 'ROLE_EDITOR',
  Customer = 'ROLE_CUSTOMER',
  ServicePartner = 'ROLE_RETAILER',
  User = 'ROLE_USER'
}
