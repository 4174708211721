import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {BehaviorSubject, Observable, throwError, of} from 'rxjs';
import {AuthService} from '../services/auth.service';
import {Session} from '../models/session';
import {catchError, filter, switchMap, take} from 'rxjs/operators';
import {ToastService} from '@ui/services/toast.service';
import {Toast, ToastType} from '@ui/models/toast';

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {
  private isRefreshing = false;
  private refreshStream: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  constructor(
    private authService: AuthService,
    private toastService: ToastService
  ) {
  }

  private static addToken(request: HttpRequest<any>, token: string): HttpRequest<unknown> {
    // dont add token to unprotected requests
    if (CustomHttpInterceptor.isProtectedRoute(request.url)) {
      return request.clone<unknown>({
        setHeaders: {
          accept: 'application/json',
        },
      });
    }
    return request.clone<unknown>({
      setHeaders: {
        accept: 'application/ld+json',
        authorization: `Bearer ${token}`,
      },
    });
  }

  private static isProtectedRoute(url: string): boolean {
    return url.includes('/auth') || url.includes('/auth/refresh');
  }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (this.authService.isAuthenticated) {
      request = CustomHttpInterceptor.addToken(request, this.authService.jwtToken);
    }
    return next.handle(request).pipe<HttpEvent<any>>(
      catchError<any, Observable<HttpEvent<any>>>(error => {
        if (error instanceof HttpErrorResponse) {
          if (error.status === 401 && !CustomHttpInterceptor.isProtectedRoute(request.url)) {
            return this.handleExpiredToken(request, next);
          }
          if (error.status === 403 && !this.isTokenExpired(this.authService.jwtToken)) {
            return this.handleAccessDenied();
          }
        }
        return throwError(error);
      }),
    );
  }

  private handleExpiredToken(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    // if the token is already refreshing, wait for each further http request until behaviour subject has an token
    if (this.isRefreshing) {
      return this.refreshStream.pipe(
        filter(token => token != null),
        take(1),
        switchMap(jwt => next.handle(CustomHttpInterceptor.addToken(request, jwt))),
      );
    }
    // refresh session and ensure, that only the first call of this functions fetches a new token
    else {
      this.isRefreshing = true;
      this.refreshStream.next(null);
      return this.authService.refreshSession().pipe(
        catchError(err => {
          this.authService.logout();
          return throwError(err);
        }),
        switchMap((session: Session) => {
          this.isRefreshing = false;
          this.refreshStream.next(session.token);
          return next.handle(CustomHttpInterceptor.addToken(request, session.token));
        }),
      );
    }
  }

  private handleAccessDenied(): Observable<HttpEvent<unknown>> {
    this.toastService.addToast(new Toast({
      text: `Leider fehlen Dir die notwendigen Zugriffsrechte`,
      type: ToastType.Error,
    }));
    return of(null);
  }

  private isTokenExpired(token: string) {
    const expiry = (JSON.parse(atob(token.split('.')[1]))).exp;
    return (Math.floor((new Date()).getTime() / 1000)) >= expiry;
  }
}
