import {Component, Input, OnInit} from '@angular/core';
import {GenericInputComponent} from '../generic-input.component';
import {ControlContainer} from '@angular/forms';

@Component({
  selector: 'app-input-checkbox',
  templateUrl: './input-checkbox.component.html',
  styleUrls: ['./input-checkbox.component.scss', '../generic-input.component.scss']
})
export class InputCheckboxComponent extends GenericInputComponent implements OnInit {
  @Input() value: boolean;

  constructor(private control: ControlContainer) {
    super(control);
  }

  ngOnInit(): void {
    this.init();
  }
}
