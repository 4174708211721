import {Component, EventEmitter, HostListener, Input, Output} from '@angular/core';
import {DatepickerService} from '../../service/datepicker.service';

@Component({
  selector: 'app-datepicker-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
})
export class DatepickerDropdownComponent {

  @Input() minDate: Date;
  @Input() maxDate: Date;
  @Output() documentEvents = new EventEmitter<Event>();

  constructor(
    public datePickerService: DatepickerService,
  ) {
  }

  selectDate(event, day, minDate, maxDate){
    event.stopPropagation();  // prevent closing of filter-modal
   return this.datePickerService.dayIsAllowed(day, minDate, maxDate) ? this.datePickerService.selectDay(day) : null;
  }

  @HostListener('document:click', ['$event']) documentClick(event: MouseEvent): void {
    this.documentEvents.emit(event);
  }

  @HostListener('document:keyup', ['$event']) documentKeyup(event: KeyboardEvent): void {
    this.documentEvents.emit(event);
  }
}
