<main>
  <app-sharea-logo></app-sharea-logo>
  <div class="contact-wrapper">
    <div>
      <figure>
        <div><img width="200" height="200" src="assets/images/eurorad-logo.svg" alt="Eurorad Logo">
        </div>
      </figure>
    </div>

    <div>
      <p>eurorad Deutschland GmbH<br />
        Longericher Str. 2<br />
        50739 Köln</p>
      <p>Telefon: +49 221 17959 670<br />
        Telefax: +49 221 17959 74</p>
      <p>Email: leasing(at)eurorad.de</p>
    </div>
  </div>
</main>
