<div class="input">
  <label class="input__label" for="file-{{controlName}}">{{ label }}</label>
  <div class="input__field">
    <label class="input__field__text"
           for="file-{{controlName}}">{{ fileName || 'Datei auswählen (.png oder .jpg)' }}</label>
    <img class="input__field__image" *ngIf="image" [src]="image" alt="Dateivorschau"/>
  </div>
  <input class="input__file" type="file" accept="image/png, image/jpeg" (change)="onFileChange($event)"
         id="file-{{controlName}}"/>
</div>
