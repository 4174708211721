import {Component, Input, OnInit} from '@angular/core';
import {LabelledRoute} from '../../routes';
import {AuthService} from '../../services/auth.service';
import {Role} from '@ui/enums/user-role.enum';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss']
})
export class NavigationComponent implements OnInit{
  @Input() routes: LabelledRoute[];

  constructor(
    private authService: AuthService
  ){}

  ngOnInit() {
    if (this.authService.userHasRole([Role.ServicePartner])){
      this.routes = this.routes.filter(route => route.path !== 'user-groups');
    }
  }
}
