import {Injectable} from '@angular/core';
import {Toast, ToastType} from '../models/toast';

@Injectable({
  providedIn: 'root'
})
export class ToastService {
  toasts: Toast[] = [];

  addToast(toast: Toast): void {
    this.toasts.push(toast);
    if (toast.autoClose) {
      setTimeout(() => this.removeToast(toast), toast.autoCloseDelay);
    }
  }

  removeToast(toast: Toast): void {
    this.toasts = this.toasts.filter(t => t.id !== toast.id);
  }

  removeAllToasts(): void {
    this.toasts = [];
  }

  showErrorMessage(): void {
    this.addToast(new Toast({
      text: 'Die Funktion konnte leider nicht ausgeführt werden. Falls der Fehler erneut auftritt probieren ' +
        'Sie es bitte nach ein paar Minuten erneut',
      type: ToastType.Error
    }));
  }

  showSuccessMessage(): void {
    this.addToast(new Toast({
      text: 'Die Aktion war erfolgreich.',
      type: ToastType.Success
    }));
  }
}
