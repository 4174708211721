import {Component, Input} from '@angular/core';
import {AbstractControl, ControlContainer, FormControl} from '@angular/forms';

@Component({
  selector: 'app-generic-input',
  template: '',
})
export class GenericInputComponent {
  @Input() controlName: string;
  @Input() label: string;
  @Input() errorMessage?: string;

  formControl: FormControl;
  hasRequiredValidator: boolean;

  constructor(public controlContainer: ControlContainer) {
  }

  get controlExists(): boolean {
    return !!this.abstractControl;
  }

  get hasValue(): boolean {
    // 0s are valid values in enums
    return !!this.currentValue || this.currentValue === 0;
  }

  get currentValue(): any {
    return this.abstractControl?.value;
  }

  private get abstractControl(): AbstractControl | null | undefined {
    return this.controlContainer?.control?.get(this.controlName);
  }

  setCurrentValue(value: any): void {
    this.abstractControl?.setValue(value);
  }

  isRequiredField(formControlData: AbstractControl) {
    let hasRequiredProperty: boolean;
    if (!formControlData || !formControlData.validator) {
      return;
    } else {
      const formControlValidator = formControlData.validator({} as AbstractControl);
      hasRequiredProperty = (formControlValidator && formControlValidator.required) ? true : false;
    }
    return hasRequiredProperty;
  }

  init(): void {
    if (!this.controlContainer?.control) {
      return;
    }
    this.formControl = this.controlContainer.control.get(this.controlName) as FormControl;
    this.hasRequiredValidator = this.isRequiredField(this.formControl);
  }
}
