<div *ngIf="label" class="dp-label">
  <label [innerHTML]="label"></label><span *ngIf="isRequired">*</span>
</div>

<div [ngClass]="{'open': toggleDropdown,'error': hasError,'disabled': disabled,'placeholder': !datePickerService.selectedDate}"
     class="dp-wrapper">
  <div
    [innerHTML]="validationErrorMessage"
    [ngClass]="{'show-error': dateFieldError}"
    class="error-message"
  >
  </div>
  <div class="dp-input">
    <app-datepicker-date-field
      (dateTyped)="onTypedDate($event)"
      (showError)="onShowError($event)"
      [disabled]="disabled"
      [dropdownOpen]="toggleDropdown"
      class="dp-text-field"
    ></app-datepicker-date-field>
    <div class="dp-input__buttons">
      <div (click)="onResetSelectedDate($event)" *ngIf="showResetTrigger()" class="dp-icon"><i class="icon-close"></i></div>
      <div (click)="toggle()" *ngIf="showToggle()" class="dp-toggle"><i class="icon-kalender"></i></div>
    </div>


    <app-datepicker-dropdown
      (documentEvents)="onDocumentEvents($event)"
      *ngIf="toggleDropdown"
      [maxDate]="maxDate"
      [minDate]="minDate"
    ></app-datepicker-dropdown>
  </div>
</div>

