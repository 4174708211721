import {Injectable} from '@angular/core';
import {ApiService} from '../../app/services/api.service';
import {Observable, of} from 'rxjs';
import {MediaObject} from '../interfaces/media-object';
import {environment} from '../../../environments/environment';
import {map} from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class MediaService {

  constructor(
    private apiService: ApiService,
  ) {
  }

  uploadFile(file: File): Observable<MediaObject> {
    if (!file) {
      return of(null);
    }
    return this.apiService.post('/api/media_objects', this.createFormData(file));
  }

  updateFile(id: string, file: File): Observable<MediaObject> {
    if (!file) {
      return of(null);
    }
    return this.apiService.post('/api/media_objects/' + id, this.createFormData(file));
  }

  resolveFile(fileUrl: string): Observable<string> {
    return this.apiService.get<MediaObject>(fileUrl).pipe(
      map(file => environment.MEDIA_HOST + file.contentUrl)
    );
  }

  private createFormData(file: File): FormData {
    const formData = new FormData();
    formData.append('file', file); // create a FormData and append the input file to ensure valid http request
    return formData;
  }
}
