<div class="portal" [class.show-sidebar]="isLoggedIn">
  <div class="portal__sidebar">
    <app-sharea-logo></app-sharea-logo>
    <!-- rerender app navigation on user change by using ngif -->
    <app-navigation *ngIf="isLoggedIn" [routes]="allRoutes"></app-navigation>
  </div>
  <div class="portal__content">
    <div class="portal__content__menu" *ngIf="isLoggedIn">
      <app-user-menu [user]="currentUser" (logoutClicked)="onLogoutClicked()"></app-user-menu>
    </div>
    <router-outlet></router-outlet>
    <app-footer class="footer"></app-footer>
  </div>
  <app-toast></app-toast>
</div>
<app-data-modal></app-data-modal>
