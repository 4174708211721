<div class="login">
    <app-sharea-logo class="login__logo"></app-sharea-logo>
    <div class="login__wrapper">
      <h1>Passwort vergessen</h1>
      <div app-card>
        <h4>Bitte geben Sie den Sicherheitscode ein, den wir Ihnen an Ihre E-Mail-Adresse {{storedEmail}} geschickt haben und vergeben Sie Ihr neues Passwort.</h4>
        <form class="login__form" [formGroup]="form" (ngSubmit)="onSubmit()">
          <app-input-text controlName="token" label="Sicherheitscode" errorMessage="Bitte geben Sie Ihre den Sicherheitscode ein"></app-input-text>
          <app-input-password controlName="password" label="Passwort"></app-input-password>
          <app-input-password controlName="passwordRepeat" label="Passwort wiederholen"></app-input-password>
          <div class="password-hints">
            <i class="icon-point" [class.invalid]="form.hasError('hasMinLength')"> <span>Min. 8 Zeichen</span> </i>
            <i class="icon-point" [class.invalid]="form.hasError('hasCapital')">  <span>Min. ein Großbuchstabe</span></i>
            <i class="icon-point" [class.invalid]="form.hasError('hasLower')"> <span>Min. ein Kleinbuchstabe</span></i>
            <i class="icon-point" [class.invalid]="form.hasError('hasNumber')">  <span>Min. eine Zahl</span> </i>
          </div>
          <div class="button-row">
            <app-button [disabled]="!form.valid" label="Passwort speichern"></app-button>
            <app-button class="text-button" design="text" label="Zurück" (click)="goToLoginPage()"></app-button>
          </div>
        </form>
      </div>
    </div>
  </div>