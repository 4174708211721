import {Component, HostBinding, OnInit, ViewChild} from '@angular/core';
import {ContentHostDirective} from '@ui/directives/content-host.directive';
import {DataModalService} from '../../services/data-modal.service';

@Component({
  selector: 'app-data-modal',
  templateUrl: 'data-modal.component.html',
  styleUrls: ['data-modal.component.scss'],
})

export class DataModalComponent implements OnInit {

  @ViewChild(ContentHostDirective, {static: true}) contentRef: ContentHostDirective;

  constructor(
    private dataModalService: DataModalService
  ) {
  }

  @HostBinding('class.active') get isActive(): boolean {
    return this.dataModalService.isOpen;
  }

  get modal() {
    return this.dataModalService.currentModal;
  }

  get title(): string {
    return this.modal?.title;
  }

  get resetLabel(): string {
    return this.modal?.buttonLabels.reset;
  }

  get cancelLabel(): string {
    return this.modal?.buttonLabels.cancel;
  }

  get confirmLabel(): string {
    return this.modal?.buttonLabels.confirm;
  }

  get content(): string {
    return this.modal?.content;
  }

  get showResetButton(): boolean {
    return this.dataModalService.showResetButton;
  }

  ngOnInit() {
    /**
     * Passing Reference to content-area of modal, in case a modal need to render a component dynamically
     */
    this.dataModalService.init(this.contentRef?.viewContainerRef);
  }

  cancel() {
    this.dataModalService.cancelModal();
  }

  confirm() {
    this.dataModalService.confirmModal();
  }

  reset() {
    this.dataModalService.resetModalComponent();
  }
}
